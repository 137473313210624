import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import OptimisationBackButton from "../OptimisationBackButton";

interface OptimisationPageWrapperProps {
  children: React.ReactNode;
  onBackClick?: () => void;
}

const OptimisationPageWrapper: React.FC<OptimisationPageWrapperProps> = ({
  children,
  onBackClick,
}) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={styles.optimisationWrapper}>
      <div className={styles.backButtonWrapper}>
        <OptimisationBackButton onClick={handleBackClick} />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default OptimisationPageWrapper;
