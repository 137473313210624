export type MetricData = {
  name: string;
  value: number;
  change: number;
  color: string;
};

export const metricsData: MetricData[] = [
  { name: "Total Sales", value: 140035, change: 10, color: "#4CAF50" },
  { name: "Orders", value: 3172439, change: 10, color: "#4CAF50" },
  { name: "Units Sold", value: 3172439, change: 10, color: "#4CAF50" },
  { name: "Net Profit", value: 3172439, change: 10, color: "#4CAF50" },
  { name: "Organic CTR", value: 3172439, change: 10, color: "#4CAF50" },
  { name: "Organic CVR", value: 3172439, change: 10, color: "#4CAF50" },
  { name: "Ad Impressions", value: 3172439, change: 10, color: "#4CAF50" },
  { name: "Ad Sales", value: 3172439, change: 10, color: "#4CAF50" },
  { name: "Ad Spend", value: 3172439, change: 10, color: "#4CAF50" },
  { name: "ACOS", value: 3172439, change: 10, color: "#4CAF50" },
  { name: "Ad CTR", value: 3172439, change: 10, color: "#4CAF50" },
  { name: "Ad CVR", value: 3172439, change: 10, color: "#4CAF50" },
];
