export const data = [
  { date: "1 Jan", actualSales: 120000, forecastedSales: 100000, cvr: 30 },
  { date: "7 Jan", actualSales: 150000, forecastedSales: 120000, cvr: 40 },
  { date: "14 Jan", actualSales: 130000, forecastedSales: 110000, cvr: 35 },
  { date: "21 Jan", actualSales: 110000, forecastedSales: 105000, cvr: 25 },
  { date: "1 Feb", actualSales: 105000, forecastedSales: 100000, cvr: 20 },
  { date: "7 Feb", actualSales: 102000, forecastedSales: 98000, cvr: 18 },
  { date: "14 Feb", actualSales: 100000, forecastedSales: 97000, cvr: 15 },
  { date: "21 Feb", actualSales: 98000, forecastedSales: 95000, cvr: 12 },
];
