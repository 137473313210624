import { Message as MessageType } from "@services/chat/chat.service";
import style from "./style.module.scss";
import classNames from "classnames";
import Logo from "@assets/icons/logo";

export const Message = ({ message }: { message: MessageType }) => {
  if (message.sender === "bot") {
    return (
      <div className={classNames(style.message, style.left)}>
        <div className={style.message__avatar}>
          <Logo />
        </div>
        <div className={style.message__text}>{message.text}</div>
      </div>
    );
  }
  return (
    <div className={classNames(style.message, style.right)}>
      <div className={style.message__text}>{message.text}</div>
    </div>
  );
};
