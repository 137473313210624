import React from "react";
import styles from "./styles.module.scss";

interface VideoTitleProps {
  title: string;
}

const VideoTitle: React.FC<VideoTitleProps> = ({ title }) => {
  return <h5 className={styles.title}>{title}</h5>;
};

export default VideoTitle;
