import React from "react";
import styles from "./styles.module.scss";
import FallArrow from "@assets/icons/FallArrow";
import SuccessArrow from "@assets/icons/SuccessArrow";
import IconStar from "@assets/icons/iconStar";

const ProblemSolution: React.FC = () => {
  return (
    <div className={styles.problemSolution}>
      <div className={styles.problemSection}>
        <h4>Problem</h4>
        <p>
          <FallArrow />
          Lack of essential parts or accessories
        </p>
        <p>
          Many reviewers were disappointed to find that their product did not
          come with essential parts or accessories, such as pressure gauge,
          canning rack, or a user manual.
        </p>
      </div>
      <div className={styles.solutionSection}>
        <h4>Solution</h4>
        <p>
          <SuccessArrow />
          Comprehensive accessory kit
        </p>
        <p>
          Include all essential parts such as pressure gauges, canning racks,
          and detailed user manuals to enhance functionality and user
          experience.
        </p>
        <button className={styles.predictionButton}>
          <IconStar /> NYLE PREDICTION INSIGHT
        </button>
      </div>
    </div>
  );
};

export default ProblemSolution;
