import { Api } from "src/utils/api";
import { AMAZON_URL } from "../amazonUrl";
import { ApiResponse } from "src/types/apiResponce";

export class CategoriesService extends Api {
  constructor() {
    super(AMAZON_URL);
  }

  public async getCategories(
    seller_id: string,
    offset: number = 0,
    limit: number = 5,
  ): Promise<ApiResponse<any>> {
    return this.get("/categories", { seller_id, offset, limit });
  }
}
