import Tools from "./components/Tools";
import ProductDate from "./components/ProductDate";
import Sections from "./components/Sections";

function CROPage() {
  return (
    <>
      <Tools />
      <ProductDate />
      <Sections />
    </>
  );
}

export default CROPage;
