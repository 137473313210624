import aspectOptimization from "@assets/icons/ToolsIcons/aspectOptimization";
import OptimazationDetails from "@assets/icons/ToolsIcons/optimazationDetails";
import EventMap from "@assets/icons/ToolsIcons/eventMap";
import TotalSalesMetrics from "@assets/icons/ToolsIcons/totalSalesMetrics";
import Waterfall from "@assets/icons/ToolsIcons/waterfall";

const toolsData = [
  { icon: OptimazationDetails, label: "Total listing optimization score" },
  { icon: OptimazationDetails, label: "Listing optimization details" },
  { icon: aspectOptimization, label: "Aspect optimization" },
  { icon: EventMap, label: "Event map" },
  { icon: EventMap, label: "Metrics builder" },
  { icon: TotalSalesMetrics, label: "Total sales metrics" },
  { icon: TotalSalesMetrics, label: "Ad sales metrics" },
  { icon: Waterfall, label: "Waterfall" },
  { icon: Waterfall, label: "Price optimization" },
];

export default toolsData;
