import React from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/router/routes";
import ProductInput from "@components/ProductInput";
import ProductCode from "@components/ProductCode";

const ProductInfo: React.FC = () => {
  const navigate = useNavigate();

  const handleComparisonClick = () => {
    navigate(`/${ROUTES.COMPARE}`);
  };

  return (
    <div className={styles.productInfo}>
      <div className={styles.productDetails}>
        <div className={styles.productCode}>
          <ProductInput label={"Your product"} isProductSelected={false} />
        </div>
        <div className={styles.compareButtonBox}>
          <button
            className={styles.compareButton}
            onClick={handleComparisonClick}
          >
            COMPARE
          </button>
        </div>
      </div>
      <div className={styles.code}>
        <ProductCode code={"B07GWVP5RC"} />
      </div>
    </div>
  );
};

export default ProductInfo;
