export interface FormField {
  id: string;
  label: string;
  name: string;
}

export const formFields: FormField[] = [
  { id: "aspects", label: "Aspects", name: "aspects" },
  {
    id: "instructions",
    label: "Additional Instructions For AI Assistant",
    name: "instructions",
  },
  { id: "hypothesis", label: "Hypothesis Example", name: "hypothesis" },
  { id: "customerSay", label: "What Customers Say", name: "customerSay" },
];
