import React, { useState } from "react";
import { useTable, useExpanded, Column } from "react-table";
import SectionContainer from "../components/SectionContainer";
import TopSectionIcons from "../components/TopSectionIcons";
import styles from "./styles.module.scss";
import { mockData } from "./mock";
import { Year } from "./types";

interface TotalSalesMetricsProps {
  handleRemove: () => void;
}

const columns: Column<Year>[] = [
  { Header: "Year", accessor: "year" },
  { Header: "Review Score", accessor: "reviewScore" },
  { Header: "Negative Price Aspect", accessor: "negativePriceAspect" },
  { Header: "Price", accessor: "price" },
  { Header: "Price Subscribers", accessor: "priceSubscribers" },
  { Header: "Discount Subscribers", accessor: "discountSubscribers" },
  { Header: "Buy Box", accessor: "buyBox" },
  { Header: "N", accessor: "n" },
  { Header: "COGS", accessor: "cogs" },
  { Header: "Estimated Fees", accessor: "estimatedFees" },
  { Header: "Estimated Storage Fees", accessor: "estimatedStorageFees" },
  { Header: "CAC", accessor: "cac" },
  { Header: "LTV", accessor: "ltv" },
  { Header: "LTV/CAC", accessor: "ltvCac" },
  { Header: "ROI", accessor: "roi" },
];

function TotalSalesMetrics({ handleRemove }: TotalSalesMetricsProps) {
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: mockData,
      },
      useExpanded,
    );

  const handleExpandClick = (rowIndex: string, level: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [`${level}-${rowIndex}`]: !prev[`${level}-${rowIndex}`],
    }));
  };

  return (
    <SectionContainer>
      <TopSectionIcons handleRemove={handleRemove} />
      <div className={styles.filterBar}>
        <div className={styles.filterItem}>
          <label htmlFor="product" className={styles.label}>
            Product
          </label>
          <select id="product" className={styles.select}>
            <option>Sugarbear Hair Vitamins...</option>
          </select>
        </div>
        <div className={styles.filterItem}>
          <label htmlFor="yearMonth" className={styles.label}>
            Year Month
          </label>
          <div className={styles.datePickers}>
            <input
              id="startDate"
              className={styles.datePicker}
              defaultValue="2023-01-01"
            />
            <input
              id="endDate"
              className={styles.datePicker}
              defaultValue="2023-12-31"
            />
          </div>
        </div>
      </div>
      <div className={styles.tableContainer}>
        <table {...getTableProps()} className={styles.table}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th></th>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <tr {...row.getRowProps()}>
                    <td>
                      <button
                        className={`${styles.expandRow} ${expandedRows[`year-${row.index}`] ? styles.expanded : ""}`}
                        onClick={() =>
                          handleExpandClick(row.index.toString(), "year")
                        }
                      >
                        {expandedRows[`year-${row.index}`] ? "-" : "+"}
                      </button>
                    </td>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className={styles.leftAligned}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                  {expandedRows[`year-${row.index}`] &&
                    row.original.quarters.map((quarter, qIndex) => (
                      <React.Fragment key={`${row.index}-${qIndex}`}>
                        <tr>
                          <td>
                            <button
                              className={`${styles.expandRow} ${expandedRows[`quarter-${row.index}-${qIndex}`] ? styles.expanded : ""}`}
                              onClick={() =>
                                handleExpandClick(
                                  `${row.index}-${qIndex}`,
                                  "quarter",
                                )
                              }
                            >
                              {expandedRows[`quarter-${row.index}-${qIndex}`]
                                ? "-"
                                : "+"}
                            </button>
                          </td>
                          <td
                            colSpan={columns.length - 1}
                            className={styles.subHeader}
                          >
                            {quarter.quarter}
                          </td>
                        </tr>
                        {expandedRows[`quarter-${row.index}-${qIndex}`] &&
                          quarter.months.map((month, mIndex) => (
                            <React.Fragment
                              key={`${row.index}-${qIndex}-${mIndex}`}
                            >
                              <tr>
                                <td>
                                  <button
                                    className={`${styles.expandRow} ${expandedRows[`month-${row.index}-${qIndex}-${mIndex}`] ? styles.expanded : ""}`}
                                    onClick={() =>
                                      handleExpandClick(
                                        `${row.index}-${qIndex}-${mIndex}`,
                                        "month",
                                      )
                                    }
                                  >
                                    {expandedRows[
                                      `month-${row.index}-${qIndex}-${mIndex}`
                                    ]
                                      ? "-"
                                      : "+"}
                                  </button>
                                </td>
                                <td
                                  colSpan={columns.length - 1}
                                  className={styles.subHeader}
                                >
                                  {month.month}
                                </td>
                              </tr>
                              {expandedRows[
                                `month-${row.index}-${qIndex}-${mIndex}`
                              ] &&
                                month.weeks.map((week, wIndex) => (
                                  <React.Fragment
                                    key={`${row.index}-${qIndex}-${mIndex}-${wIndex}`}
                                  >
                                    <tr>
                                      <td>
                                        <button
                                          className={`${styles.expandRow} ${expandedRows[`week-${row.index}-${qIndex}-${mIndex}-${wIndex}`] ? styles.expanded : ""}`}
                                          onClick={() =>
                                            handleExpandClick(
                                              `${row.index}-${qIndex}-${mIndex}-${wIndex}`,
                                              "week",
                                            )
                                          }
                                        >
                                          {expandedRows[
                                            `week-${row.index}-${qIndex}-${mIndex}-${wIndex}`
                                          ]
                                            ? "-"
                                            : "+"}
                                        </button>
                                      </td>
                                      <td
                                        colSpan={columns.length - 1}
                                        className={styles.subHeader}
                                      >
                                        {week.week}
                                      </td>
                                    </tr>
                                    {expandedRows[
                                      `week-${row.index}-${qIndex}-${mIndex}-${wIndex}`
                                    ] &&
                                      week.days.map((day, dIndex) => (
                                        <tr
                                          key={`${row.index}-${qIndex}-${mIndex}-${wIndex}-${dIndex}`}
                                        >
                                          <td>
                                            <button
                                              className={`${styles.expandRow} ${expandedRows[`day-${row.index}-${qIndex}-${mIndex}-${wIndex}-${dIndex}`] ? styles.expanded : ""}`}
                                              onClick={() =>
                                                handleExpandClick(
                                                  `${row.index}-${qIndex}-${mIndex}-${wIndex}-${dIndex}`,
                                                  "day",
                                                )
                                              }
                                            >
                                              {expandedRows[
                                                `day-${row.index}-${qIndex}-${mIndex}-${wIndex}-${dIndex}`
                                              ]
                                                ? "-"
                                                : "+"}
                                            </button>
                                          </td>
                                          <td
                                            colSpan={columns.length - 1}
                                            className={styles.subHeader}
                                          >
                                            {day.day}
                                          </td>
                                        </tr>
                                      ))}
                                  </React.Fragment>
                                ))}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    ))}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </SectionContainer>
  );
}

export default TotalSalesMetrics;
