import {
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  Area,
} from "recharts";
import { format } from "date-fns";
import styles from "./styles.module.scss";
import { data } from "./mock";

const convertDate = (timestamp) => {
  return format(new Date(timestamp), "MMM");
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.customTooltip}>
        <p className={styles.label}>{`Date : ${convertDate(label)}`}</p>
        {payload.map((entry, index) => (
          <p key={index} style={{ color: entry.color }}>
            {`${entry.name} : ${entry.value}%`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const CustomLegend = ({ payload }) => {
  return (
    <div className={styles.customLegend}>
      {payload.map((entry, index) => (
        <div key={`item-${index}`} className={styles.legendItem}>
          <span
            className={styles.legendColor}
            style={{ backgroundColor: entry.color }}
          />
          <span className={styles.legendText}>{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

const ReviewScore = () => {
  return (
    <div className={styles.container}>
      <h1>Review score</h1>
      <div className={styles.reviewScoreChart}>
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id="colorYourProduct" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.1} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorCompetitor" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.1} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Legend
              content={<CustomLegend payload={undefined} />}
              verticalAlign="top"
              align="center"
              wrapperStyle={{ paddingBottom: 10 }}
            />
            <CartesianGrid stroke="#444" strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={convertDate}
              stroke="#7C7C78"
            />
            <YAxis type="number" domain={[0, 70]} stroke="#7C7C78" />
            <Tooltip
              content={
                <CustomTooltip
                  active={undefined}
                  payload={undefined}
                  label={undefined}
                />
              }
            />
            <Area
              type="monotone"
              dataKey="yourProduct"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorYourProduct)"
              name="Your product"
            />
            <Area
              type="monotone"
              dataKey="competitor"
              stroke="#82ca9d"
              fillOpacity={1}
              fill="url(#colorCompetitor)"
              name="Competitor"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ReviewScore;
