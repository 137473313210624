import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";

const randomValueFromMocks = (mockData: IWidget[]) => {
  const randomIndex = Math.floor(Math.random() * mockData.length);
  return {
    value: mockData[randomIndex].value,
    difference: mockData[randomIndex].difference,
  };
};

const existingMocks: IWidget[] = [
  {
    id: "1",
    name: "Orders",
    value: "59",
    difference: "+0.02",
  },
  {
    id: "2",
    name: "Total Sales",
    value: "$24470.1",
    difference: "-$345.2",
  },
  {
    id: "3",
    name: "Units sold",
    value: "33",
    difference: "+17%",
  },
  {
    id: "4",
    name: "Contribution profit",
    value: "$6336",
    difference: "+2%",
  },
  {
    id: "5",
    name: "Organic CTR",
    value: "0.11%",
    difference: "+0.02%",
  },
  {
    id: "6",
    name: "Organic CVR",
    value: "0.12%",
    difference: "+0.02%",
  },
  {
    id: "7",
    name: "Review score",
    value: "3.5",
    difference: "+0.1%",
  },
  {
    id: "8",
    name: "Value from Nyle",
    value: "$3878.2",
    difference: "+0.1%",
  },
];

export const widgetMocks: IWidget[] = [
  ...existingMocks,
  {
    id: "9",
    name: "Total Impressions",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "10",
    name: "Total CTR",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "11",
    name: "Total Clicks",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "12",
    name: "Total CVR",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "13",
    name: "Total Orders",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "14",
    name: "Total Units Sold",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "15",
    name: "Total Sales",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "16",
    name: "Total Spend",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "17",
    name: "Ad Impressions",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "18",
    name: "Ad CTR",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "19",
    name: "Ad Clicks",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "20",
    name: "Ad CVR",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "21",
    name: "Ad Orders",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "22",
    name: "Ad Units Sold",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "23",
    name: "Ad Sales",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "24",
    name: "Ad Spend",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "25",
    name: "Organic Impressions",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "26",
    name: "Organic CTR",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "27",
    name: "Organic Clicks",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "28",
    name: "Organic CVR",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "29",
    name: "Organic Orders",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "30",
    name: "Organic Units Sold",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "31",
    name: "Organic Sales",
    ...randomValueFromMocks(existingMocks),
  },
  {
    id: "32",
    name: "Organic Spend",
    ...randomValueFromMocks(existingMocks),
  },
];
