export default () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.07031"
        y="1.75"
        width="29.8556"
        height="29.5"
        rx="6.75"
        stroke="#5C5C5A"
        strokeWidth="2.5"
      />
      <path
        d="M12.8441 16.948C10.9881 16.948 9.99609 15.716 9.99609 13.716C9.99609 11.748 10.9721 10.5 12.8281 10.5C14.6841 10.5 15.6601 11.764 15.6601 13.732C15.6601 15.732 14.7001 16.948 12.8441 16.948ZM11.9161 13.748C11.9161 14.708 12.1241 15.412 12.8281 15.412C13.5321 15.412 13.7241 14.724 13.7241 13.748C13.7241 12.708 13.5481 12.036 12.8281 12.036C12.1081 12.036 11.9161 12.724 11.9161 13.748ZM13.6441 22.116L18.0441 10.676H19.8361L15.4361 22.116H13.6441ZM20.7321 22.356C18.8761 22.356 17.8841 21.124 17.8841 19.124C17.8841 17.156 18.8601 15.908 20.7161 15.908C22.5721 15.908 23.5481 17.172 23.5481 19.14C23.5481 21.14 22.5881 22.356 20.7321 22.356ZM19.8041 19.156C19.8041 20.116 20.0121 20.82 20.7161 20.82C21.4201 20.82 21.6121 20.132 21.6121 19.156C21.6121 18.116 21.4361 17.444 20.7161 17.444C19.9961 17.444 19.8041 18.132 19.8041 19.156Z"
        fill="#5C5C5A"
      />
    </svg>
  );
};
