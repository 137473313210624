import { useEffect, useRef } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface CustomInputProps {
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  blue?: boolean;
}

const CustomInput: React.FC<CustomInputProps> = ({
  value,
  onChange,
  blue = false,
}) => {
  const rangeRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (rangeRef.current) {
      const track = rangeRef.current;
      const val =
        ((value - Number(track.min)) /
          (Number(track.max) - Number(track.min))) *
        100;
      track.style.setProperty("--value", `${val}%`);
    }
  }, [value]);

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  return (
    <div className={styles.inputContainer}>
      <input
        type="number"
        value={value}
        onChange={onChange}
        className={classNames(styles.inputNumber, {
          [styles.inputNumberBlue]: blue,
        })}
      />
      <input
        type="range"
        min="0"
        max="100"
        value={value}
        onChange={handleRangeChange}
        className={classNames(styles.inputRange, {
          [styles.inputRangeBlue]: blue,
        })}
        ref={rangeRef}
      />
    </div>
  );
};

export default CustomInput;
