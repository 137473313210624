export default () => {
  return (
    <svg
      width="36"
      height="32"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 21.75H4M34 21.75H31.5M4 21.75V27.75C4 28.8546 4.89543 29.75 6 29.75H11C12.1046 29.75 13 28.8546 13 27.75V4.25C13 3.14543 13.8954 2.25 15 2.25H20C21.1046 2.25 22 3.14543 22 4.25V13.75M4 21.75H22M22 13.75H29.5C30.6046 13.75 31.5 14.6454 31.5 15.75V21.75M22 13.75V21.75M31.5 21.75H22"
        stroke="#5C5C5A"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};
