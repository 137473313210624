import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import tickMark from "@assets/icons/tickMark.png";
import { optimizationContent } from "./mock";
import Pointer from "@assets/icons/pointer";
import Graph from "./components/Graph";
import TopSectionIcons from "../components/TopSectionIcons";
import SectionContainer from "../components/SectionContainer";

interface TotalOptimizationProps {
  handleRemove: () => void;
}

function TotalOptimization({ handleRemove }: TotalOptimizationProps) {
  const [percentage] = useState(20);
  const [color, setColor] = useState("#FE5858");
  const [isProblemClosed, setIsProblemClosed] = useState(true);

  useEffect(() => {
    if (percentage < 33) {
      setColor("#FE5858");
    } else if (percentage < 66) {
      setColor("#D89A3D");
    } else {
      setColor("#80C67A");
    }
  }, [percentage]);

  const angle = -60 + (percentage / 100) * 260;

  const contentHidden = () => {
    setIsProblemClosed((prevState) => !prevState);
  };

  return (
    <SectionContainer>
      <TopSectionIcons
        handleRemove={handleRemove}
        contentHidden={contentHidden}
        isContentClosed={isProblemClosed}
      />
      <div className={styles.gaugeContainer}>
        <div className={styles.gauge}>
          <img src={tickMark} alt="Tick Mark" className={styles.tickMark} />
          <div className={styles.pointerContainer}>
            <Pointer angle={angle} color={color} />
          </div>
          <div className={styles.graphContainer}>
            <Graph percentage={percentage} color={color} />
          </div>
        </div>
        {isProblemClosed && (
          <div className={styles.content}>
            <h3 className={styles.title}>{optimizationContent.title}</h3>
            <p className={styles.description}>
              {optimizationContent.description
                .split(" <br /> ")
                .map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          </div>
        )}
      </div>
    </SectionContainer>
  );
}

export default TotalOptimization;
