import Chart from "chart.js/auto";
import {
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";
import { useRef, useState, useEffect } from "react";
import { TooltipData } from "./tooltip-types";
import { useMoveDot } from "./useMoveDot";
import { Radar } from "react-chartjs-2";
import { options } from "./chart-data";
import { RadarTooltip } from "./RadarTooltip";

Chart.register(LineElement, PointElement, Tooltip, Legend, RadialLinearScale);

interface RadarChartProps {
  datasets: any[];
  activeIndex: number;
  blockChanging?: boolean;
}

export const RadarChart: React.FC<RadarChartProps> = ({
  datasets,
  activeIndex,
  blockChanging = false,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [tooltipData, setTooltipData] = useState<TooltipData>({
    dataPoints: [],
    caretY: -1,
    caretX: -1,
    title: "",
    index: -1,
  });

  const chartRef = useRef<Chart<"radar", number[], string>>(null);
  useMoveDot(chartRef);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;

      chart.data.datasets.forEach((dataset: any) => {
        const borderColor = dataset.borderColor;

        dataset.pointBackgroundColor = dataset.data.map(
          (_: number, index: number) =>
            index === activeIndex ? borderColor : "transparent",
        );
        dataset.pointBorderColor = dataset.data.map(
          (_: number, index: number) =>
            index === activeIndex ? borderColor : "transparent",
        );
        dataset.pointRadius = dataset.data.map((_: number, index: number) =>
          index === activeIndex ? 6 : 6,
        );
        dataset.pointHoverRadius = dataset.data.map(
          (_: number, index: number) => (index === activeIndex ? 12 : 8),
        );
      });

      chart.update();
    }
  }, [activeIndex]);

  return (
    <div
      style={{
        width: 400,
        height: 400,
        position: "relative",
      }}
    >
      <Radar
        ref={chartRef}
        options={{
          ...options,
          plugins: {
            legend: { display: false },
            tooltip: {
              enabled: !blockChanging,
              external: ({ tooltip }) => {
                if (!blockChanging) {
                  const needOpen = tooltip.opacity === 1;
                  if (!needOpen) {
                    setIsTooltipOpen(false);
                    return;
                  }
                  const caretNotMove =
                    tooltip.caretY === tooltipData.caretY &&
                    tooltip.caretX === tooltipData.caretX;
                  if (isTooltipOpen && caretNotMove) return;
                  setTooltipData({
                    dataPoints: tooltip.dataPoints,
                    caretY: tooltip.caretY,
                    caretX: tooltip.caretX,
                    title: tooltip.title[0] ?? "",
                    index: tooltip.dataPoints[0]?.dataIndex ?? -1,
                  });
                  setIsTooltipOpen(true);
                }
              },
            },
          },
          interaction: {
            mode: blockChanging ? null : "nearest",
            intersect: !blockChanging,
          },
          events: blockChanging
            ? []
            : ["mousemove", "mouseout", "click", "touchstart", "touchmove"],
        }}
        data={{
          labels: [
            "Effectiveness",
            "Ingredients",
            "Price",
            "Value",
            "Side effects",
            "Taste",
            "Brand",
          ],
          datasets,
        }}
      />
      <RadarTooltip data={tooltipData} isOpen={isTooltipOpen} />
    </div>
  );
};
