import React, { useEffect } from "react";
import PageWrapper from "@components/PageWrapper/PageWrapper";
import Header from "./components/Header";
import ProductInfo from "./components/ProductInfo";
import TopProblems from "./components/TopProblems";
import Reviews from "./components/Reviews";
import TopPerformance from "./components/TopPerformance";
import Aspects from "./components/AspectsAndPrases";
import ReviewScore from "./components/ReviewScore";
import { AmazonService } from "@services/amazon/amazon.service";
import { useProductStore } from "src/store/overviewProduct.state";

export const Overview: React.FC = () => {
  const { selectedProduct } = useProductStore();

  useEffect(() => {
    if (selectedProduct && selectedProduct.asin) {
      const fetchInsights = async () => {
        try {
          await Promise.all([
            AmazonService.insights.getHypotheses(selectedProduct.asin),
            AmazonService.insights.getAspects(selectedProduct.asin),
            AmazonService.insights.getPhrases(selectedProduct.asin),
            AmazonService.insights.getProblems(selectedProduct.asin),
          ]);
        } catch (error) {
          console.error("Error fetching insights:", error);
        }
      };

      fetchInsights();
    }
  }, [selectedProduct]);

  return (
    <PageWrapper>
      <Header />
      <ProductInfo />
      <ReviewScore />
      <Aspects />
      <TopProblems />
      <Reviews />
      <TopPerformance />
    </PageWrapper>
  );
};
