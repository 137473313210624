import styles from "./styles.module.scss";
import { mockData } from "./mock";
import ProductCode from "@components/ProductCode";
import ProductInput from "@components/ProductInput";

function Products() {
  const getStarReviews = (reviews) => {
    const starRatings = [
      { label: "5 STAR", value: reviews["5_star"] },
      { label: "4 STAR", value: reviews["4_star"] },
      { label: "3 STAR", value: reviews["3_star"] },
      { label: "2 STAR", value: reviews["2_star"] },
      { label: "1 STAR", value: reviews["1_star"] },
    ];

    return starRatings;
  };

  return (
    <div className={styles.container}>
      {mockData.map((product, index) => (
        <div key={index} className={styles.product}>
          <div className={styles.productCode}>
            <ProductInput label={product.type} value={product.name} />
            <ProductCode code={"B07GWVP5RC"} />
          </div>
          <div className={styles.imgRateContainer}>
            <img
              src={product.image}
              alt={product.name}
              className={styles.image}
            />
            <div className={styles.details}>
              <span className={styles.price}>${product.price}</span>
              <div className={styles.rating}>
                {[...Array(5)].map((_star, index) => {
                  const ratingValue = index + 1;
                  return (
                    <span key={index} className={styles.star}>
                      {ratingValue <= product.rating ? "★" : "☆"}
                    </span>
                  );
                })}
                <span className={styles.ratingValue}>{product.rating}.0</span>
              </div>
              <ul className={styles.reviews}>
                {getStarReviews(product.reviews).map((review, index) => (
                  <li key={index} className={styles.reviewItem}>
                    <span className={styles.reviewLabel}>{review.label}</span>
                    <div
                      className={styles.reviewBar}
                      style={{ width: `${review.value}%` }}
                    >
                      <div
                        className={styles.reviewFill}
                        style={{ width: `${review.value}%` }}
                      ></div>
                    </div>
                    <span className={styles.reviewPercentage}>
                      {review.value}%
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Products;
