import React, { useState } from "react";
import styles from "./styles.module.scss";
import Plus from "@assets/icons/plus";
import Minus from "@assets/icons/minus";
import { hypotheses } from "./mocks";

const Hypotheses: React.FC = () => {
  const [openSections, setOpenSections] = useState(
    hypotheses.map(() => ({
      hypothesis: false,
      howToTest: false,
      potentialImpact: false,
    })),
  );

  const toggleSection = (index: number, section: string) => {
    setOpenSections((prevState) =>
      prevState.map((item, idx) => {
        if (idx === index) {
          return { ...item, [section]: !item[section] };
        } else {
          return item;
        }
      }),
    );
  };

  return (
    <div className={styles.hypothesesContainer}>
      <h2>
        Try the hypothesis <br /> <span>in the case</span>
      </h2>
      <div className={styles.hypothesesGrid}>
        {hypotheses.map((item, index) => (
          <div key={index} className={styles.hypothesisCard}>
            <div className={styles.cardHeader}>
              <span className={styles.hypothesisNumber}>
                Hypothesis {index + 1}
              </span>
            </div>
            <div className={styles.hypothesisContainer}>
              <div className={styles.forecasted}>
                <div className={styles.forecastedItem}>
                  <p>+7%</p>
                  <span>Forecasted CTR</span>
                </div>
                <div className={styles.forecastedItem}>
                  <p>+6%</p>
                  <span>Forecasted Sales</span>
                </div>
              </div>
              <div className={styles.details}>
                <h3>{item.title}</h3>
                <div
                  className={styles.detailItem}
                  onClick={() => toggleSection(index, "hypothesis")}
                >
                  <span>Hypothesis</span>
                  <span>
                    {openSections[index].hypothesis ? <Minus /> : <Plus />}
                  </span>
                </div>
                {openSections[index].hypothesis && (
                  <div className={styles.detailContent}>
                    <p>{item.hypothesis}</p>
                  </div>
                )}
                <div
                  className={styles.detailItem}
                  onClick={() => toggleSection(index, "howToTest")}
                >
                  <span>How to Test</span>
                  <span>
                    {openSections[index].howToTest ? <Minus /> : <Plus />}
                  </span>
                </div>
                {openSections[index].howToTest && (
                  <div className={styles.detailContent}>
                    <p>{item.howToTest}</p>
                  </div>
                )}
                <div
                  className={styles.detailItem}
                  onClick={() => toggleSection(index, "potentialImpact")}
                >
                  <span>Potential Impact</span>
                  <span>
                    {openSections[index].potentialImpact ? <Minus /> : <Plus />}
                  </span>
                </div>
                {openSections[index].potentialImpact && (
                  <div className={styles.detailContent}>
                    <p>{item.potentialImpact}</p>
                  </div>
                )}
              </div>
              <button className={styles.tryItOut}>
                TRY IT OUT <span>&rarr;</span>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Hypotheses;
