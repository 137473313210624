import { Api } from "src/utils/api";
import { AMAZON_URL } from "../amazonUrl";
import { ApiResponse } from "src/types/apiResponce";

export class InsightsService extends Api {
  constructor() {
    super(AMAZON_URL);
  }

  public async getAspects(asin: string): Promise<ApiResponse<any>> {
    return this.get(`/insights/aspects/${asin}`, {});
  }

  public async getProblems(asin: string): Promise<ApiResponse<any>> {
    return this.get(`/insights/problems/${asin}`, {});
  }

  public async getHypotheses(asin: string): Promise<ApiResponse<any>> {
    return this.get(`/insights/hypotheses/${asin}`, {});
  }

  public async getPhrases(asin: string): Promise<ApiResponse<any>> {
    return this.get(`/insights/phrases/${asin}`, {});
  }
}
