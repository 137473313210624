import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import { Widget } from "@pages/Dashboard/features/Widgets/Widget";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state.ts";

interface WidgetsListProps {
  widgets: IWidget[];
}

const WidgetsList: React.FC<WidgetsListProps> = ({ widgets }) => {
  const [widgetList, setWidgetList] = useState(widgets);
  const [draggingWidget, setDraggingWidget] = useState<IWidget | null>(null);
  const setWidgets = useDashboardStore((state) => state.setWidgets);
  const setModalWidgets = useDashboardStore((state) => state.setModalWidgets);
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  useEffect(() => {
    setWidgetList(widgets.slice(0, 8));
  }, [widgets]);

  useEffect(() => {
    setWidgets(widgetList);
    setModalWidgets(widgetList);
  }, [widgetList, setWidgets, setModalWidgets]);

  const handleDragStart = (e, index) => {
    setDraggingIndex(index);
    setDraggingWidget(widgetList[index]);
    e.dataTransfer.setData("widgetIndex", index.toString());
    if (e.currentTarget) {
      e.currentTarget.classList.add(styles.dragging);
    }
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    if (draggingIndex !== null) {
      const tempList = [...widgetList];
      if (draggingWidget) {
        const filteredList = tempList.filter(
          (widget) => widget !== draggingWidget,
        );
        filteredList.splice(dropIndex, 0, draggingWidget);
        setWidgetList(filteredList);
      }
    }
    setDraggingWidget(null);
    setDraggingIndex(null);
    setHoverIndex(null);
    document.querySelectorAll(`.${styles.widgetItem}`).forEach((item) => {
      if (item) {
        item.classList.remove(
          styles.hovering,
          styles.dragging,
          styles.placeholder,
        );
      }
    });
  };

  const handleDragOver = (e, hoverIndex) => {
    e.preventDefault();
    if (draggingIndex === null || draggingIndex === hoverIndex) return;
    setHoverIndex(hoverIndex);
  };

  const handleDragEnd = (e) => {
    if (e.currentTarget) {
      e.currentTarget.classList.remove(styles.dragging);
    }
    document.querySelectorAll(`.${styles.widgetItem}`).forEach((item) => {
      if (item) {
        item.classList.remove(styles.hovering, styles.placeholder);
      }
    });
    setDraggingWidget(null);
    setDraggingIndex(null);
  };

  const handleDragEnter = (e) => {
    if (draggingIndex !== null && e.currentTarget) {
      e.currentTarget.classList.add(styles.hovering);
    }
  };

  const handleDragLeave = (e) => {
    if (e.currentTarget) {
      e.currentTarget.classList.remove(styles.hovering);
    }
  };

  return (
    <div className={styles.widgetsList}>
      <div className={styles.flexBox}>
        {widgetList.map((widget, index) => (
          <div
            key={widget.id}
            className={`${styles.widgetItem} ${draggingIndex === index ? styles.dragging : ""} ${hoverIndex === index ? styles.placeholder : ""}`}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDrop={(e) => handleDrop(e, index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDragEnd={handleDragEnd}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          >
            <Widget
              widget={widget}
              additionalClass={
                draggingIndex === index ? styles.childElementClass : ""
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(WidgetsList);
