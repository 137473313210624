import React, { useState } from "react";
import styles from "./styles.module.scss";
import { RadarChart } from "@components/Spider/RadarChart";
import { datasets, headerTexts } from "./mock";
import SwiperNavigation from "@components/ProductSwiper";
import DynamicComparisonBlock from "./ComparisonBlock";
import ProblemSolution from "./ProblemSolution";

const ProductComparison: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < headerTexts.length - 1 ? prevIndex + 1 : 0,
    );
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : headerTexts.length - 1,
    );
  };

  const dynamicRatingProduct1 = datasets[0].data[activeIndex];
  const dynamicRatingProduct2 = datasets[1].data[activeIndex];
  const difference = (dynamicRatingProduct2 - dynamicRatingProduct1).toFixed(1);
  const improvementValue = (0.2 + activeIndex * 0.1).toFixed(1);

  return (
    <div className={styles.container}>
      <div className={styles.headContent}>
        <div className={styles.swiperBox}>
          <SwiperNavigation
            headerText={headerTexts[activeIndex]}
            onNext={handleNext}
            onPrev={handlePrev}
            showText={true}
            improvementValue={improvementValue}
          />
        </div>
        <DynamicComparisonBlock
          productScore={dynamicRatingProduct1}
          competitorScore={dynamicRatingProduct2}
          difference={difference}
        />
      </div>
      <div className={styles.comparison}>
        <ProblemSolution />
        <div className={styles.radarBox}>
          <RadarChart
            datasets={datasets}
            activeIndex={activeIndex}
            blockChanging={false}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductComparison;
