export default () => {
  return (
    <svg
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1862_24304)">
        <path
          d="M0.320312 12.9184C7.67433 10.3605 15.9663 10.3605 23.3203 12.9184C24.4666 13.3171 25.6356 13.6537 26.8203 13.9281"
          stroke="#5C5C5A"
          strokeWidth="2.5"
        />
        <path
          d="M0.320312 14.9184C7.67433 17.4764 15.9663 17.4764 23.3203 14.9184C30.6743 12.3605 38.9663 12.3605 46.3203 14.9184V17"
          stroke="#5C5C5A"
          strokeWidth="2.5"
        />
      </g>
      <rect
        x="2.07031"
        y="1.25"
        width="29.8556"
        height="29.5"
        rx="6.75"
        stroke="#5C5C5A"
        strokeWidth="2.5"
      />
      <defs>
        <clipPath id="clip0_1862_24304">
          <rect x="0.820312" width="32.3556" height="32" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
