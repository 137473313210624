export default () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 11.8333L30 11.8333M11.3333 2.5L11.3333 30.5M9.46667 2.5H22.5333C25.1469 2.5 26.4537 2.5 27.452 3.00864C28.33 3.45605 29.044 4.16995 29.4914 5.04804C30 6.0463 30 7.35309 30 9.96667V23.0333C30 25.6469 30 26.9537 29.4914 27.952C29.044 28.83 28.33 29.544 27.452 29.9914C26.4537 30.5 25.1469 30.5 22.5333 30.5H9.46667C6.85309 30.5 5.5463 30.5 4.54804 29.9914C3.66995 29.544 2.95605 28.83 2.50864 27.952C2 26.9537 2 25.6469 2 23.0333V9.96667C2 7.35309 2 6.0463 2.50864 5.04804C2.95605 4.16995 3.66995 3.45605 4.54804 3.00864C5.5463 2.5 6.85309 2.5 9.46667 2.5Z"
        stroke="#5C5C5A"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
