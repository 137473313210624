import { Api } from "src/utils/api";
import { useMathStore } from "@pages/Dashboard/store/math.state.ts";
import { MATH_BASE_URL } from "./mathUrl";

export class MathService extends Api {
  private static instance: MathService;

  private constructor() {
    super(MATH_BASE_URL);
  }

  private static getInstance(): MathService {
    if (!MathService.instance) {
      MathService.instance = new MathService();
    }
    return MathService.instance;
  }

  public static async getSalesTotal(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get("/sales/total", {
      date_start,
      date_end,
    });
    useMathStore.getState().setSalesTotalData(data);
  }

  public static async getAov(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get("/aov", {
      date_start,
      date_end,
    });
    useMathStore.getState().setAovData(data);
  }

  public static async getAdsImpressions(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/adds-sales/impressions",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setAdsImpressionsData(data);
  }

  public static async getAdsClicks(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/adds-sales/clicks",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setAdsClicksData(data);
  }

  public static async getAdsOrders(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/adds-sales/orders",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setAdsOrdersData(data);
  }

  public static async getAdsUnitsSold(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/adds-sales/units-sold",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setAdsUnitsSoldData(data);
  }

  public static async getAdsSales(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/adds-sales/sales",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setAdsSalesData(data);
  }

  public static async getAdsSpend(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/adds-sales/spend",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setAdsSpendData(data);
  }

  public static async getAdsAcos(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/adds-sales/acos",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setAdsAcosData(data);
  }

  public static async getAdsRoas(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/adds-sales/roas",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setAdsRoasData(data);
  }

  public static async getAdsCpc(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/adds-sales/cpc",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setAdsCpcData(data);
  }

  public static async getAdsCvr(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/adds-sales/cvr",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setAdsCvrData(data);
  }

  public static async getAdsCtr(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/adds-sales/ctr",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setAdsCtrData(data);
  }

  // Organic Metrics
  public static async getOrganicImpressions(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/search-query/organic/impressions",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setOrganicImpressionsData(data);
  }

  public static async getOrganicClicks(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/search-query/organic/clicks",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setOrganicClicksData(data);
  }

  public static async getOrganicCartAdds(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/search-query/organic/cart-adds",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setOrganicCartAddsData(data);
  }

  public static async getOrganicUnitsSold(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/search-query/organic/units-sold",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setOrganicUnitsSoldData(data);
  }

  public static async getOrganicCvr(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/search-query/organic/cvr",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setOrganicCvrData(data);
  }

  public static async getOrganicCtr(
    date_start: string,
    date_end: string,
  ): Promise<void> {
    const data: number = await MathService.getInstance().get(
      "/search-query/organic/ctr",
      {
        date_start,
        date_end,
      },
    );
    useMathStore.getState().setOrganicCtrData(data);
  }
}
