export default () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 10.0019C0.734784 10.0019 0.48043 9.89655 0.292893 9.70901C0.105357 9.52148 0 9.26712 0 9.00191V1.00391C0 0.73869 0.105357 0.484336 0.292893 0.2968C0.48043 0.109263 0.734784 0.00390625 1 0.00390625H9C9.26522 0.00390625 9.51957 0.109263 9.70711 0.2968C9.89464 0.484336 10 0.73869 10 1.00391V9.00191C10 9.26712 9.89464 9.52148 9.70711 9.70901C9.51957 9.89655 9.26522 10.0019 9 10.0019H1ZM15 10.0019C14.7348 10.0019 14.4804 9.89655 14.2929 9.70901C14.1054 9.52148 14 9.26712 14 9.00191V1.00391C14 0.73869 14.1054 0.484336 14.2929 0.2968C14.4804 0.109263 14.7348 0.00390625 15 0.00390625H22.998C23.2632 0.00390625 23.5176 0.109263 23.7051 0.2968C23.8926 0.484336 23.998 0.73869 23.998 1.00391V9.00191C23.998 9.26712 23.8926 9.52148 23.7051 9.70901C23.5176 9.89655 23.2632 10.0019 22.998 10.0019H15ZM1 24.0019C0.734784 24.0019 0.48043 23.8966 0.292893 23.709C0.105357 23.5215 0 23.2671 0 23.0019V15.0019C0 14.7367 0.105357 14.4823 0.292893 14.2948C0.48043 14.1073 0.734784 14.0019 1 14.0019H9C9.26522 14.0019 9.51957 14.1073 9.70711 14.2948C9.89464 14.4823 10 14.7367 10 15.0019V23.0019C10 23.2671 9.89464 23.5215 9.70711 23.709C9.51957 23.8966 9.26522 24.0019 9 24.0019H1ZM15 24.0019C14.7348 24.0019 14.4804 23.8966 14.2929 23.709C14.1054 23.5215 14 23.2671 14 23.0019V15.0019C14 14.7367 14.1054 14.4823 14.2929 14.2948C14.4804 14.1073 14.7348 14.0019 15 14.0019H22.998C23.2632 14.0019 23.5176 14.1073 23.7051 14.2948C23.8926 14.4823 23.998 14.7367 23.998 15.0019V23.0019C23.998 23.2671 23.8926 23.5215 23.7051 23.709C23.5176 23.8966 23.2632 24.0019 22.998 24.0019H15Z"
        fill=""
      />
    </svg>
  );
};
