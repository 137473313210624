import { tooltipArrayState, TooltipData } from "./tooltip-types";

export const RadarTooltip = ({
  data,
  isOpen,
}: {
  data: TooltipData;
  isOpen: boolean;
}) => {
  const tooltipState = tooltipArrayState[data.index] ?? {
    tooltipPosition: { top: 80, left: 200 },
  };
  const [yourProduct = { raw: 0 }, averageProduct = { raw: 0 }] =
    data.dataPoints;

  const yourProductRating = Number(yourProduct.raw);
  const averageProductRating = Number(averageProduct.raw);

  const difference = yourProductRating - averageProductRating;
  const status = difference > 0 ? "above" : "below";

  return (
    <div
      style={{
        position: "absolute",
        top: tooltipState.tooltipPosition.top,
        left: tooltipState.tooltipPosition.left,
        backgroundColor: "#000",
        padding: "12px 8px",
        width: "180px",
        borderRadius: "8px",
        boxShadow: "0px 0px 15px rgba(82, 149, 224, 0.3)",
        transition: "all .3s",
        opacity: isOpen ? "100" : "0",
        visibility: isOpen ? "visible" : "hidden",
        fontSize: 10,
        zIndex: 9,
      }}
    >
      <h4
        style={{
          fontSize: 11,
          marginBottom: "20px",
          fontWeight: 500,
          color: "#fff",
        }}
      >
        {data.title}
      </h4>
      <div style={{ display: "flex", gap: "8px" }}>
        {data.dataPoints.map((point, index) => {
          const isYourProduct = index === 0;
          return (
            <div
              key={index}
              style={{
                width: "100%",
                fontSize: "10px",
                paddingTop: "5px",
                borderTop: "2px solid",
                borderColor: point.dataset.borderColor as string,
              }}
            >
              <div>{point.dataset.label}</div>
              <div
                style={{
                  fontSize: 13,
                  marginTop: "auto",
                  color:
                    isYourProduct && status === "below" ? "#FE5858" : "#8AFF6C",
                }}
              >
                {point.formattedValue}
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          marginTop: "12px",
          color: status === "above" ? "#8AFF6C" : "#FE5858",
          fontSize: 11,
          textShadow: "0px 0px 8px rgba(255, 83, 83, 0.7)",
        }}
      >
        Your product is {Math.abs(difference).toFixed(2)} {status} average
      </div>
      <div style={{ display: "flex", marginTop: "10px" }}>
        <div>Expected sales after improving aspect</div>
        <div style={{ color: "#8AFF6C", marginLeft: "10px" }}>$1350.39</div>
      </div>
    </div>
  );
};
