export const optimizationData = [
  { label: "Listing optimization", percentage: 75, color: "#418C3A" },
  { label: "Photo optimization", percentage: 77, color: "#186C58" },
  { label: "Video optimization", percentage: 20, color: "#342460" },
  {
    label: "A+ content optimization",
    percentage: 0,
    color: "#FFFFFF",
    text: "Not optimized",
  },
];
