export const hypotheses = [
  {
    title: "Hair Growth",
    hypothesis:
      "Including user before-and-after photos in ads will increase CTR and CVR by showcasing evidence of hair growth.",
    howToTest:
      "Including user testimonials and scientific evidence to test the impact.",
    potentialImpact: "Significant increase in user trust and sales.",
  },
  {
    title: "Taste",
    hypothesis:
      "Focusing ad copy on the product’s delicious taste will increase its appeal and improve CVR for consumable hair growth supplements.",
    howToTest: "Conducting taste tests and gathering user feedback.",
    potentialImpact: "Improved user engagement and preference.",
  },
  {
    title: "Value/Waste of Money",
    hypothesis:
      "Ads that emphasize the cost-effectiveness of the product will increase CVR by appealing to budget-conscious consumers.",
    howToTest:
      "Comparing the product with competitors in terms of cost-effectiveness.",
    potentialImpact: "Attraction of cost-sensitive customers.",
  },
  {
    title: "Physical Effects/Changes",
    hypothesis:
      "Ads that focus on tangible benefits and physical transformations due to the product will have a higher CTR and CVR.",
    howToTest: "Conducting taste tests and gathering user feedback.",
    potentialImpact: "Improved user engagement and preference.",
  },
  {
    title: "Usage Experience",
    hypothesis:
      "Displaying user-friendly product usage in the ad will lead to a higher CVR by reducing perceived usage complexity",
    howToTest:
      "Comparing the product with competitors in terms of cost-effectiveness.",
    potentialImpact: "Attraction of cost-sensitive customers.",
  },
];
