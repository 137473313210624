import { Api } from "src/utils/api";
import { AMAZON_URL } from "../amazonUrl";
import { useProductsStore } from "src/store/product.state";
import { Product, ProductParams, SearchParams } from "./types";

export class ProductsService extends Api {
  constructor() {
    super(AMAZON_URL);
  }

  private formatParams(
    params: ProductParams | SearchParams,
  ): Record<string, string | number> {
    return Object.fromEntries(
      Object.entries(params).map(([key, value]) => [key, String(value)]),
    );
  }

  public async getOwnProducts(params: ProductParams = {}): Promise<Product[]> {
    const formattedParams = this.formatParams(params);
    const response = await this.get<Product[]>(
      "/products/own",
      formattedParams,
    );
    useProductsStore.getState().setProductOwn(response);
    return response;
  }

  public async searchOwnProducts(params: SearchParams): Promise<Product[]> {
    const formattedParams = this.formatParams(params);
    const response = await this.get<Product[]>(
      "/products/own/search",
      formattedParams,
    );
    useProductsStore.getState().setProductOwn(response);
    return response;
  }

  public async getPublicProducts(
    params: ProductParams = {},
  ): Promise<Product[]> {
    const formattedParams = this.formatParams(params);
    const response = await this.get<Product[]>(
      "/products/public",
      formattedParams,
    );
    useProductsStore.getState().setProductPublic(response);
    return response;
  }

  public async searchPublicProducts(params: SearchParams): Promise<Product[]> {
    const formattedParams = this.formatParams(params);
    const response = await this.get<Product[]>(
      "/products/public/search",
      formattedParams,
    );
    useProductsStore.getState().setProductPublic(response);
    return response;
  }
}
