import React from "react";
import styles from "./styles.module.scss";
import croProduct from "@assets/img/croProduct.png";
import ProductHeaderTitle from "../components/ProductHeaderTitle";

const ProductCard: React.FC = () => {
  return (
    <div>
      <ProductHeaderTitle title="Product detail page" />
      <div className={styles.productCard}>
        <div className={styles.card}>
          <img
            src={croProduct}
            alt="Sugarbear Hair Vitamins"
            className={styles.productImage}
          />
          <div className={styles.productInfo}>
            <p>Gummy · 60 Count (Pack of 1)</p>
            <div className={styles.rating}>
              <span>⭐ 4.5</span>
              <span>47,975</span>
            </div>
            <p className={styles.price}>
              $29.99 <span>($0.50/Count)</span>
            </p>
            <p>$28.49 with Subscribe & Save discount</p>
            <p className={styles.prime}>prime</p>
            <p>FREE delivery Thu, Apr 25 on $35 of items shipped by Amazon</p>
            <p>Or fastest delivery Today 7 AM - 11 AM</p>
            <p>Small Business</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
