const MySvgComponent = ({ baseColor, uniqueId }) => {
  return (
    <svg
      width="177"
      height="93"
      viewBox="0 0 177 93"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id={`paint0_linear_${uniqueId}`}
          x1="176"
          y1="7.91579"
          x2="-4.60866e-06"
          y2="7.91569"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#202021" />
          <stop offset="0.41" stopColor={baseColor} />
          <stop offset="1" stopColor={lightenColor(baseColor, 0.5)} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_${uniqueId}`}
          x1="177"
          y1="53.1053"
          x2="0.999995"
          y2="53.1052"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#202021" />
          <stop offset="0.41" stopColor={baseColor} />
          <stop offset="1" stopColor={lightenColor(baseColor, 0.5)} />
        </linearGradient>
        <linearGradient
          id={`paint2_linear_${uniqueId}`}
          x1="1"
          y1="15.9158"
          x2="176"
          y2="15.9157"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#202021" />
          <stop offset="0.41" stopColor={baseColor} />
          <stop offset="1" stopColor={lightenColor(baseColor, 0.5)} />
        </linearGradient>
        <linearGradient
          id={`paint3_linear_${uniqueId}`}
          x1="1"
          y1="58.1368"
          x2="177"
          y2="58.1368"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#202021" />
          <stop offset="0.41" stopColor={baseColor} />
          <stop offset="1" stopColor={lightenColor(baseColor, 0.5)} />
        </linearGradient>
      </defs>
      <path
        d="M88 7.67376C116.137 17.9054 147.863 17.9054 176 7.67376V16H0V7.67376C28.1371 -2.55792 59.8629 -2.55792 88 7.67376Z"
        fill={`url(#paint0_linear_${uniqueId})`}
      />
      <path d="M1 16H177V91H1V16Z" fill={`url(#paint1_linear_${uniqueId})`} />
      <path
        d="M88.5 15.6738C60.5228 25.9054 28.9772 25.9054 1 15.6738V24H176V15.6738C148.023 5.44208 116.477 5.44208 88.5 15.6738Z"
        fill={`url(#paint2_linear_${uniqueId})`}
      />
      <path d="M177 24H1V93H177V24Z" fill={`url(#paint3_linear_${uniqueId})`} />
    </svg>
  );
};

const lightenColor = (color, percent) => {
  const num = parseInt(color.replace("#", ""), 16),
    amt = Math.round(2.55 * percent * 100),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt;
  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
    (B < 255 ? (B < 1 ? 0 : B) : 255)
  )
    .toString(16)
    .slice(1)}`;
};

export default MySvgComponent;
