import { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import ChevronDown from "@assets/icons/chevron-down";
import AmazonIcon from "@assets/icons/AmazonIcon.png";
import AmazonBlackIcon from "@assets/icons/AmazonBlackIcon.png";
import US from "@assets/icons/US.png";
import FR from "@assets/icons/France.png";
import Shopify from "@assets/icons/Shopify.png";
import Brasil from "@assets/icons/Brasil.png";
import Canada from "@assets/icons/Canada.png";
import Combine from "@assets/img/combine.png";
import { useOutsideClick } from "src/utils/useOutsideClick";

const CustomSelect = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);
  const dropdownRef = useOutsideClick(() => setIsOpen(false));

  useEffect(() => {
    setIsAnyCheckboxChecked(Object.values(checkedItems).some((item) => item));
  }, [checkedItems]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleCheckboxes = (e) => {
    e.stopPropagation();
    setShowCheckboxes(!showCheckboxes);
  };

  const handleCheckboxClick = (e, index) => {
    e.stopPropagation();
    setCheckedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleApplyClick = (e) => {
    e.stopPropagation();
    setCheckedItems({});
    setShowCheckboxes(false);
    setIsOpen(false);
  };

  const renderDropdownItems = () => {
    const items = [
      { src: US, alt: "US", label: "www.amazon.com" },
      { src: Brasil, alt: "Brasil", label: "www.amazon.com.br" },
      { src: Canada, alt: "Canada", label: "www.amazon.ca" },
      { src: FR, alt: "France", label: "www.amazon.com.fr" },
      { src: Shopify, alt: "Shopify", label: "www.shopify.com" },
    ];

    return items.map((item, index) => (
      <div key={index} className={styles.dropdownItem}>
        {showCheckboxes && (
          <button onClick={(e) => handleCheckboxClick(e, index)}>
            <input
              type="checkbox"
              id={`checkbox-${index}`}
              className={styles["customCheckbox"]}
              checked={!!checkedItems[index]}
              onChange={(e) => handleCheckboxClick(e, index)}
            />
            <label
              htmlFor={`checkbox-${index}`}
              className={`${styles["customCheckboxLabel"]} ${checkedItems[index] ? styles.checked : ""}`}
            />
          </button>
        )}
        <img src={item.src} alt={item.alt} />
        <span>{item.label}</span>
      </div>
    ));
  };

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <div
        className={`${styles.dropdownContainer} ${isOpen ? styles.dropdownActive : ""}`}
        onClick={toggleDropdown}
      >
        <div
          className={`${styles.customSelect} ${isOpen ? styles.active : ""}`}
        >
          <img src={isOpen ? AmazonBlackIcon : AmazonIcon} alt="Amazon" />
          <span>Amazon</span>
          <ChevronDown />
        </div>
        {isOpen && (
          <div className={styles.dropdownMenu}>
            {renderDropdownItems()}
            <div
              className={`${styles.combineButton} ${showCheckboxes ? styles.applybtn : ""} ${
                isAnyCheckboxChecked ? styles.checkedButton : ""
              }`}
              onClick={showCheckboxes ? handleApplyClick : toggleCheckboxes}
            >
              {showCheckboxes ? (
                <>Apply</>
              ) : (
                <>
                  Combine <img src={Combine} alt="Combine" />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
