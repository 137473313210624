import React, { useState } from "react";
import OptimisationPageWrapper from "../../components/OptimisationPageWrapper";
import { videoCardMock } from "./mock";
import ButtonsSection from "../../components/OptimisationInfoSection/ButtonSection";
import { VisibleDescriptions } from "../../components/OptimisationInfoSection/types";
import InfoSection from "../../components/OptimisationInfoSection";
import VideoSection from "./components/VideoSection";
import VideoParams from "./components/VideoParams";
import styles from "./styles.module.scss";

const VideoOptimisation: React.FC = () => {
  const [isHypothesisVisible, setIsHypothesisVisible] = useState(false);

  const [visibleDescriptions, setVisibleDescriptions] =
    useState<VisibleDescriptions>({
      description: false,
      critique: false,
      hypothesis: false,
      howToTest: false,
      percentageImpact: false,
    });

  const toggleHypothesisVisibility = () => {
    setIsHypothesisVisible((prevState) => !prevState);
  };

  const toggleVisibility = (field: keyof VisibleDescriptions) => {
    setVisibleDescriptions((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <OptimisationPageWrapper>
      <div className={styles.container}>
        <VideoSection />
        <VideoParams />
      </div>
      {isHypothesisVisible && (
        <InfoSection
          type="video"
          description={videoCardMock.videoDescription}
          critique={videoCardMock.videoCritique}
          hypothesis={videoCardMock.hypothesis}
          howToTest={videoCardMock.howToTest}
          percentageImpact={videoCardMock.percentageImpact}
          visibleDescriptions={visibleDescriptions}
          toggleVisibility={toggleVisibility}
        />
      )}
      <ButtonsSection
        type="video"
        toggleHypothesisVisibility={toggleHypothesisVisibility}
        isHypothesisVisible={isHypothesisVisible}
      />
    </OptimisationPageWrapper>
  );
};

export default VideoOptimisation;
