import { TooltipItem } from "chart.js";

export type TooltipData = {
  dataPoints: TooltipItem<"radar">[];
  caretX: number;
  caretY: number;
  title: string;
  index: number;
};

export type TooltopState = {
  dragInfo: { direction: "x" | "y"; reverse: boolean };
  tooltipPosition: { top: number; left: number };
};

export const tooltipArrayState: TooltopState[] = [
  {
    dragInfo: { direction: "y", reverse: false },
    tooltipPosition: { top: 80, left: 200 },
  },
  {
    dragInfo: { direction: "y", reverse: false },
    tooltipPosition: { top: 120, left: 190 },
  },
  {
    dragInfo: { direction: "x", reverse: true },
    tooltipPosition: { top: 210, left: 80 },
  },
  {
    dragInfo: { direction: "y", reverse: true },
    tooltipPosition: { top: 220, left: 0 },
  },
  {
    dragInfo: { direction: "y", reverse: true },
    tooltipPosition: { top: 0, left: -150 },
  },
  {
    dragInfo: { direction: "x", reverse: false },
    tooltipPosition: { top: 165, left: -130 },
  },
  {
    dragInfo: { direction: "y", reverse: false },
    tooltipPosition: { top: 80, left: -140 },
  },
];
