import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import inputArrow from "@assets/icons/inputArrow.svg";
import ProductSelectionModal from "@pages/Overview/components/ProductSelectionModal";
import { useProductStore } from "src/store/overviewProduct.state";

interface ProductInputProps {
  label: string;
  isProductSelected?: boolean;
}

const ProductInput: React.FC<ProductInputProps> = ({
  label,
  isProductSelected,
}) => {
  const { selectedProduct } = useProductStore();
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!selectedProduct && isProductSelected === false) {
      setModalOpen(true);
    }
  }, [selectedProduct, isProductSelected]);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleProductSelect = () => {
    setModalOpen(false);
  };

  const getInputValue = (product: typeof selectedProduct | null): string => {
    if (!product) return "";
    return product.item_name || product.title || "";
  };

  return (
    <div className={styles.inputBox}>
      <h4 className={styles.title}>{label}</h4>
      <div className={styles.inputContainer}>
        <input
          className={styles.inputText}
          value={getInputValue(selectedProduct)}
          readOnly
        />
        <button onClick={openModal} className={styles.dropdownIcon}>
          <img src={inputArrow} width={12} height={12} alt="Dropdown Icon" />
        </button>
      </div>
      <ProductSelectionModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSelect={handleProductSelect}
      />
    </div>
  );
};

export default ProductInput;
