import React, { useState } from "react";
import styles from "./styles.module.scss";
import { RadarChart } from "@components/Spider/RadarChart";
import Phases from "./Phases";
import { datasets, headerTexts } from "./mock";
import SwiperNavigation from "@components/ProductSwiper";
import ProductCard from "@components/ProductCard";

const AspectsAndPrases: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const improvementValue = (0.2 + activeIndex * 0.1).toFixed(1);

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < headerTexts.length - 1 ? prevIndex + 1 : 0,
    );
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : headerTexts.length - 1,
    );
  };

  const dynamicRating = datasets[0].data[activeIndex];

  return (
    <div className={styles.container}>
      <div className={styles.aspects}>
        <h3>Aspects</h3>
        <div className={styles.aspectsContainer}>
          <div className={styles.radarBox}>
            <RadarChart
              datasets={datasets}
              activeIndex={activeIndex}
              blockChanging={true}
            />
          </div>
          <div className={styles.infoBox}>
            <SwiperNavigation
              headerText={headerTexts[activeIndex]}
              onNext={handleNext}
              onPrev={handlePrev}
            />
            <ProductCard
              rating={dynamicRating}
              headerText={headerTexts[activeIndex]}
              improvementText={`Improving this aspect you will improve review score by ${improvementValue}`}
            />
          </div>
        </div>
      </div>

      <div className={styles.pharesContainer}>
        <Phases />
      </div>
    </div>
  );
};

export default AspectsAndPrases;
