import React from "react";
import styles from "./styles.module.scss";
import CloseProblem from "@assets/icons/closeProblem";
import OpenProblem from "@assets/icons/openproblem";
import CloseIcon from "@components/CloseIcon";
import WidgetIcon from "@assets/icons/widgetsDNDBtn";

interface TopSectionIconsProps {
  handleRemove: () => void;
  contentHidden?: () => void;
  isContentClosed?: boolean;
}

const TopSectionIcons: React.FC<TopSectionIconsProps> = ({
  handleRemove,
  contentHidden,
  isContentClosed,
}) => {
  return (
    <div className={styles.iconsContainer}>
      <WidgetIcon />
      <div className={styles.icons}>
        {contentHidden && isContentClosed !== undefined && (
          <div onClick={contentHidden} className={styles.openIcon}>
            {isContentClosed ? <CloseProblem /> : <OpenProblem />}
          </div>
        )}
        <CloseIcon onClick={handleRemove} />
      </div>
    </div>
  );
};

export default TopSectionIcons;
