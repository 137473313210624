import styles from "./styles.module.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
  LabelList,
} from "recharts";
import TopSectionIcons from "../components/TopSectionIcons";
import SectionContainer from "../components/SectionContainer";
import { data } from "./mock";

const CustomizedAxisTick = ({
  x,
  y,
  payload,
}: {
  x: number;
  y: number;
  payload: { value: string };
}) => {
  const lines = payload.value
    .split(" ")
    .map((word, index, wordsArray) => {
      let currentLine = word;
      for (let i = index + 1; i < wordsArray.length; i++) {
        if (currentLine.length + wordsArray[i].length < 10) {
          currentLine += ` ${wordsArray[i]}`;
          wordsArray[i] = "";
        } else {
          break;
        }
      }
      return currentLine;
    })
    .filter((line) => line !== "");

  return (
    <g transform={`translate(${x},${y + 10})`}>
      {lines.map((line, index) => (
        <text
          key={index}
          x={0}
          y={index * 12}
          textAnchor="middle"
          fill="#fff"
          fontSize="10"
        >
          {line}
        </text>
      ))}
    </g>
  );
};

const WhatIf = ({ handleRemove }: { handleRemove: () => void }) => {
  return (
    <SectionContainer>
      <TopSectionIcons handleRemove={handleRemove} isContentClosed={false} />
      <div className={styles.whatIfContainer}>
        <h3 className={styles.title}>What-If Analysis</h3>
        <ResponsiveContainer width={627} height={400}>
          <BarChart
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 80 }}
          >
            <CartesianGrid horizontal={true} vertical={false} />
            <XAxis
              dataKey="name"
              tick={
                <CustomizedAxisTick
                  x={0}
                  y={0}
                  payload={{
                    value: "",
                  }}
                />
              }
              interval={0}
            />
            <YAxis
              tickFormatter={(value: number) => `${value / 1000000}M`}
              tick={{ fill: "white" }}
            />
            <Bar dataKey="base" stackId="a" fill="transparent">
              {data.map((_, index) => (
                <Cell key={`cell-base-${index}`} />
              ))}
            </Bar>
            <Bar dataKey="value" stackId="a" barSize={30}>
              {data.map((entry, index) => (
                <Cell key={`cell-value-${index}`} fill={entry.color} />
              ))}
              <LabelList
                dataKey="value"
                position="top"
                formatter={(value: number) => `${Math.abs(value) / 1000000}M`}
                fill="white"
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </SectionContainer>
  );
};

export default WhatIf;
