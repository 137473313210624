import { create } from "zustand";

interface Section {
  id: number;
  label: string;
  component: React.ReactNode;
}

interface SectionState {
  sectionsBefore: Section[];
  sectionsAfter: Section[];
  addSectionBefore: (section: Section) => void;
  addSectionAfter: (section: Section) => void;
  removeSectionBefore: (id: number) => void;
  removeSectionAfter: (id: number) => void;
  reorderSections: (
    source: { droppableId: string; index: number },
    destination: { droppableId: string; index: number },
  ) => void;
}

export const useSectionStore = create<SectionState>((set) => ({
  sectionsBefore: [],
  sectionsAfter: [],
  addSectionBefore: (section) =>
    set((state) => ({
      sectionsBefore: [...state.sectionsBefore, section],
    })),
  addSectionAfter: (section) =>
    set((state) => ({
      sectionsAfter: [...state.sectionsAfter, section],
    })),
  removeSectionBefore: (id) =>
    set((state) => ({
      sectionsBefore: state.sectionsBefore.filter(
        (section) => section.id !== id,
      ),
    })),
  removeSectionAfter: (id) =>
    set((state) => ({
      sectionsAfter: state.sectionsAfter.filter((section) => section.id !== id),
    })),
  reorderSections: (source, destination) =>
    set((state) => {
      const sourceList =
        source.droppableId === "sectionsBefore"
          ? state.sectionsBefore
          : state.sectionsAfter;
      const destinationList =
        destination.droppableId === "sectionsBefore"
          ? state.sectionsBefore
          : state.sectionsAfter;

      const [movedItem] = sourceList.splice(source.index, 1);
      destinationList.splice(destination.index, 0, movedItem);

      return {
        sectionsBefore: [...state.sectionsBefore],
        sectionsAfter: [...state.sectionsAfter],
      };
    }),
}));
