import Photo1 from "@assets/img/photoOptimisation/Photo1.png";
import Photo2 from "@assets/img/photoOptimisation/Photo2.png";
import Photo3 from "@assets/img/photoOptimisation/Photo3.png";
import Photo4 from "@assets/img/photoOptimisation/Photo4.png";
import Photo5 from "@assets/img/photoOptimisation/Photo5.png";
import Photo6 from "@assets/img/photoOptimisation/Photo6.png";
import Photo7 from "@assets/img/photoOptimisation/Photo7.png";
import Photo8 from "@assets/img/photoOptimisation/Photo8.png";

export interface PhotoCardData {
  imageUrl: string;
  optimizationScore: string;
  ctrChange: string;
  salesChange: string;
  ctrValue: string;
  salesValue: string;
  id: number;
  imageDescription: string;
  imageCritique: string;
  hypothesis: string;
  howToTest: string;
  percentageImpact: string;
}

export const photoCardMocks: PhotoCardData[] = [
  {
    imageUrl: Photo1,
    optimizationScore: "+10%",
    ctrChange: "+4%",
    salesChange: "+6%",
    ctrValue: "4%",
    salesValue: "6%",
    id: 1,
    imageDescription:
      "The description of the promotional collage is that the use of Sugarbear Hair Gummy Vitamins can lead to noticeable improvements...",
    imageCritique:
      "Limited Evidence: The primary limitation of this promotional image is its reliance solely on visual representation...",
    hypothesis:
      "Replacing the second image on the product page with an infographic that clearly displays the benefits...",
    howToTest:
      "Create a new set of promotional images that include customer testimonials about the taste and usability of the vitamins...",
    percentageImpact:
      "Expect to see a 3 to 8 percent rise in conversion rate (CVR) and a proportional rise in sales...",
  },
  {
    imageUrl: Photo2,
    optimizationScore: "+8%",
    ctrChange: "+3%",
    salesChange: "+5%",
    ctrValue: "3%",
    salesValue: "5%",
    id: 2,
    imageDescription: "Description for Photo2...",
    imageCritique: "Critique for Photo2...",
    hypothesis: "Hypothesis for Photo2...",
    howToTest: "Testing method for Photo2...",
    percentageImpact: "Expected impact for Photo2...",
  },
  {
    imageUrl: Photo3,
    optimizationScore: "+12%",
    ctrChange: "+5%",
    salesChange: "+7%",
    ctrValue: "5%",
    salesValue: "7%",
    id: 3,
    imageDescription: "Description for Photo3...",
    imageCritique: "Critique for Photo3...",
    hypothesis: "Hypothesis for Photo3...",
    howToTest: "Testing method for Photo3...",
    percentageImpact: "Expected impact for Photo3...",
  },
  {
    imageUrl: Photo4,
    optimizationScore: "+15%",
    ctrChange: "+6%",
    salesChange: "+8%",
    ctrValue: "6%",
    salesValue: "8%",
    id: 4,
    imageDescription: "Description for Photo4...",
    imageCritique: "Critique for Photo4...",
    hypothesis: "Hypothesis for Photo4...",
    howToTest: "Testing method for Photo4...",
    percentageImpact: "Expected impact for Photo4...",
  },
  {
    imageUrl: Photo5,
    optimizationScore: "+9%",
    ctrChange: "+2%",
    salesChange: "+4%",
    ctrValue: "2%",
    salesValue: "4%",
    id: 5,
    imageDescription: "Description for Photo5...",
    imageCritique: "Critique for Photo5...",
    hypothesis: "Hypothesis for Photo5...",
    howToTest: "Testing method for Photo5...",
    percentageImpact: "Expected impact for Photo5...",
  },
  {
    imageUrl: Photo6,
    optimizationScore: "+11%",
    ctrChange: "+4.5%",
    salesChange: "+6.5%",
    ctrValue: "4.5%",
    salesValue: "6.5%",
    id: 6,
    imageDescription: "Description for Photo6...",
    imageCritique: "Critique for Photo6...",
    hypothesis: "Hypothesis for Photo6...",
    howToTest: "Testing method for Photo6...",
    percentageImpact: "Expected impact for Photo6...",
  },
  {
    imageUrl: Photo7,
    optimizationScore: "+13%",
    ctrChange: "+5.5%",
    salesChange: "+7.5%",
    ctrValue: "5.5%",
    salesValue: "7.5%",
    id: 7,
    imageDescription: "Description for Photo7...",
    imageCritique: "Critique for Photo7...",
    hypothesis: "Hypothesis for Photo7...",
    howToTest: "Testing method for Photo7...",
    percentageImpact: "Expected impact for Photo7...",
  },
  {
    imageUrl: Photo8,
    optimizationScore: "+7%",
    ctrChange: "+3.5%",
    salesChange: "+5.5%",
    ctrValue: "3.5%",
    salesValue: "5.5%",
    id: 8,
    imageDescription: "Description for Photo8...",
    imageCritique: "Critique for Photo8...",
    hypothesis: "Hypothesis for Photo8...",
    howToTest: "Testing method for Photo8...",
    percentageImpact: "Expected impact for Photo8...",
  },
];
