import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "@hooks/useTitle";
import { Widgets } from "@pages/Dashboard/features/Widgets";
import { useQuery } from "react-query";
import { DashboardAPI } from "@pages/Dashboard/services/dashboard.services";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { Products } from "@pages/Dashboard/features/Products";
import { SalesPerformance } from "@pages/Dashboard/features/SalesPerformance";
import { WelcomeSection } from "@components/WelcomeSection";
import PageWrapper from "@components/PageWrapper/PageWrapper";

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("window.dashboard"));

  const { isLoading, data } = useQuery(
    ["dashboard-widgets"],
    DashboardAPI.getWidgets,
  );

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (!isLoading && data) {
      useDashboardStore.getState().setWidgets(data);
      setIsDataLoaded(true);
    }
  }, [isLoading, data]);

  return (
    <PageWrapper>
      {isDataLoaded ? (
        <>
          <WelcomeSection />
          <Widgets />
          <SalesPerformance />
          <Products />
        </>
      ) : (
        <></>
      )}
    </PageWrapper>
  );
};
