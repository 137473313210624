export const topPhrasesSet1 = [
  { phrase: "har growth", count: 423 },
  { phrase: "great taste", count: 368 },
  { phrase: "hair grow", count: 359 },
  { phrase: "taste good", count: 342 },
  { phrase: "difference hair", count: 277 },
  { phrase: "bear sugar", count: 268 },
  { phrase: "they taste", count: 249 },
  { phrase: "vitamins hair", count: 204 },
  { phrase: "hair growing", count: 202 },
  { phrase: "nails hair", count: 187 },
];

export const topPhrasesSet2 = [
  { phrase: "hair skin", count: 353 },
  { phrase: "great taste", count: 348 },
  { phrase: "good taste", count: 343 },
  { phrase: "loss hair", count: 340 },
  { phrase: "nails skin", count: 335 },
  { phrase: "hair nails", count: 333 },
  { phrase: "hair nails skin", count: 331 },
  { phrase: "hair growth", count: 330 },
  { phrase: "taste gummies", count: 325 },
  { phrase: "gummies these", count: 320 },
];
