import Options from "./components/Options";
import Generate from "./components/Generate";
import KeywordSearch from "./components/KeywordSearch";
import styles from "./styles.module.scss";
import { useState } from "react";
import OptimisationPageWrapper from "../../components/OptimisationPageWrapper";

function OptimisationDetails() {
  const [showNewBlocks, setShowNewBlocks] = useState(false);
  const [isReturning, setIsReturning] = useState(false);

  const handleGenerateClick = () => {
    setShowNewBlocks(true);
  };

  const handleBackClick = () => {
    setIsReturning(true);
    setShowNewBlocks(false);
    setIsReturning(false);
  };

  return (
    <OptimisationPageWrapper onBackClick={showNewBlocks && handleBackClick}>
      <div className={styles.optimisationContainer}>
        {!showNewBlocks ? (
          <>
            <Generate onGenerateClick={handleGenerateClick} />
            <Options />
          </>
        ) : (
          <KeywordSearch
            onBackClick={handleBackClick}
            isReturning={isReturning}
          />
        )}
      </div>
    </OptimisationPageWrapper>
  );
}

export default OptimisationDetails;
