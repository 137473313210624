import styles from "./styles.module.scss";
import CalendarButton from "@components/CalendarButton";

function Header() {
  return (
    <div className={styles.container}>
      <h1>Comparison mode</h1>
      <CalendarButton />
    </div>
  );
}

export default Header;
