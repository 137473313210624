import { Api } from "src/utils/api";
import { AMAZON_URL } from "../amazonUrl";
import { ApiResponse } from "src/types/apiResponce";

export class ReviewsService extends Api {
  constructor() {
    super(AMAZON_URL);
  }

  public async getOwnProducts(
    asin: number = 0,
    offset: number = 8,
    limit: number = 0,
  ): Promise<ApiResponse<any>> {
    return this.get("/reviews", { offset, limit, asin });
  }
}
