import styles from "./styles.module.scss";
import { topPhrasesSet1, topPhrasesSet2 } from "./mock";

const TopPhrasesComponent = () => {
  const maxCountSet1 = Math.max(...topPhrasesSet1.map((item) => item.count));
  const maxCountSet2 = Math.max(...topPhrasesSet2.map((item) => item.count));

  return (
    <div className={styles.phrasesContainer}>
      <h3>Top Phrases</h3>
      <div className={styles.phrasesList}>
        <div className={styles.phraseColumn}>
          {topPhrasesSet1.map((item, index) => (
            <div key={index} className={styles.phraseItem}>
              <span className={styles.phraseText}>{item.phrase}</span>
              <span className={styles.phraseCount}>{item.count}</span>
              <div
                className={styles.phraseBar}
                style={{ width: `${(item.count / maxCountSet1) * 100}%` }}
              />
            </div>
          ))}
          <button className={styles.moreButton}>+10 MORE</button>
        </div>
        <div className={styles.phraseColumn}>
          {topPhrasesSet2.map((item, index) => (
            <div key={index} className={styles.phraseItem}>
              <span className={styles.phraseText}>{item.phrase}</span>
              <span className={styles.phraseCount}>{item.count}</span>
              <div
                className={styles.phraseBar}
                style={{ width: `${(item.count / maxCountSet2) * 100}%` }}
              />
            </div>
          ))}
          <button className={styles.moreButton}>+10 MORE</button>
        </div>
      </div>
    </div>
  );
};

export default TopPhrasesComponent;
