import { Api } from "src/utils/api";
import { INSTATHINK_API_BASE_URL } from "./chat.constant";

export class ChatApi extends Api {
  constructor() {
    super(INSTATHINK_API_BASE_URL);
  }

  public sendMessage = async (message: string): Promise<any> => {
    return await this.post("", {
      message,
    });
  };
}
