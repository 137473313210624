import Img1 from "@assets/img/optimisation/optimisationImg1.png";
import Img2 from "@assets/img/optimisation/optimisationImg2.png";
import Img3 from "@assets/img/optimisation/optimisationImg3.png";
import Img4 from "@assets/img/optimisation/optimisationImg4.png";
import Img5 from "@assets/img/optimisation/optimisationImg5.png";
import Img6 from "@assets/img/optimisation/optimisationImg6.png";

export const mockData = {
  title:
    "Sugarbear Vegan Hair Gummy Vitamins with Biotin, Vitamin C, Vitamin B-12, Zinc for Hair Skin & Nails (1 Month Supply)",
  bulletPoints:
    "• We designed our vitamins for those who were looking for easy to eat vitamins without the struggle of swallowing pills. Nourish your hair from within with our cruelty free gummy bear hair vitamins. • Scientifically formulated blend of Biotin, Vitamin C, Vitamin B-12, Folic Acid, Zinc, and Iodine.",
  keywords: [
    "Hair growth vitamins",
    "Hair growth for women",
    "Hair supplements for hair growth women",
    "Hair gummies",
    "Biotin chewables",
  ],
  images: [
    { src: Img1, alt: "image1" },
    { src: Img2, alt: "image2" },
    { src: Img3, alt: "image3" },
    { src: Img4, alt: "image4" },
    { src: Img5, alt: "image5" },
    { src: Img6, alt: "image6" },
  ],
  searchTerms:
    "sugarbear sugar bear hair vitamins minerals loss hair nails pills vegan",
};
