const GaugeSVG = ({ percentage }) => {
  const angle = -180 + (percentage * 180) / 100;

  return (
    <div>
      <svg width="190" height="115" viewBox="0 0 200 100">
        <defs>
          <linearGradient id="progress-gradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#80C67A" />
            <stop offset="100%" stopColor="#80C67A" stopOpacity="0.8" />
          </linearGradient>
        </defs>
        <path
          d="M 10,100 A 90,90 0 0,1 190,100"
          fill="none"
          stroke="#333"
          strokeWidth="20"
          strokeLinecap="round"
        />
        <path
          d="M 10,100 A 90,90 0 0,1 190,100"
          fill="none"
          stroke="url(#progress-gradient)"
          strokeWidth="20"
          strokeLinecap="round"
          transform={`rotate(${angle}, 100, 100)`}
        />
      </svg>
    </div>
  );
};

export default GaugeSVG;
