import { create } from "zustand";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";

export interface IDashboardState {
  widgets: IWidget[];
  availableWidgets: IWidget[];
  modalWidgets: IWidget[];
  setWidgets: (widgets: IWidget[]) => void;
  setAvailableWidgets: (widgets: IWidget[]) => void;
  setModalWidgets: (widgets: IWidget[]) => void;
  updateWidgets: (widgets: IWidget[], availableWidgets: IWidget[]) => void;
  reset: () => void;
}

export const useDashboardStore = create<IDashboardState>((set) => ({
  widgets: [],
  availableWidgets: [],
  modalWidgets: [],
  setWidgets: (widgets: IWidget[]) => set({ widgets }),
  setAvailableWidgets: (availableWidgets: IWidget[]) => {
    if (!Array.isArray(availableWidgets)) {
      return;
    }
    set({ availableWidgets });
  },
  setModalWidgets: (modalWidgets: IWidget[]) => set({ modalWidgets }),
  updateWidgets: (widgets: IWidget[], availableWidgets: IWidget[]) => {
    set({ widgets, availableWidgets });
  },
  reset: () => set({ widgets: [], availableWidgets: [], modalWidgets: [] }),
}));
