export default () => {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.87468 10.5C4.43294 10.5 4.07484 10.1419 4.07484 9.70016V3.30147C4.07484 2.85973 4.43294 2.50163 4.87468 2.50163C5.31642 2.50163 5.67451 2.85973 5.67451 3.30147V9.70016C5.67451 10.1419 5.31642 10.5 4.87468 10.5Z"
        fill="#1AC867"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25375 4.98311C9.49268 5.24943 9.49268 5.65296 9.25375 5.91928C8.97521 6.22975 8.48882 6.22975 8.21028 5.91928L5.47003 2.86495C5.15219 2.51067 4.59717 2.51067 4.27933 2.86495L1.53908 5.91928C1.26054 6.22975 0.77415 6.22975 0.495607 5.91928C0.256673 5.65296 0.256673 5.24943 0.495607 4.98311L4.27933 0.765706C4.59717 0.411431 5.15219 0.411431 5.47003 0.765706L9.25375 4.98311Z"
        fill="#1AC867"
      />
    </svg>
  );
};
