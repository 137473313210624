import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { productItems } from "./mock";
import { ROUTES } from "src/router/routes";

interface ProductPopupProps {
  closePopup: () => void;
}

export const ProductPopup: React.FC<ProductPopupProps> = ({ closePopup }) => {
  const navigate = useNavigate();

  const handleNavigation = (label: string) => {
    if (label === "My Products") {
      navigate(`${ROUTES.MYPRODUCT}`);
    }
    if (label === "CRO Module") {
      navigate(`${ROUTES.CRO}`);
    }
    closePopup();
  };

  return (
    <div className={styles.popupMenu}>
      <div className={styles.popupContent}>
        <div className={styles.section}>
          <div className={styles.section}>
            {productItems.map((item) => (
              <button
                key={item.label}
                onClick={() => handleNavigation(item.label)}
                className={styles.item}
              >
                {item.label} {item.icon}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
