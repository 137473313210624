export const MIN_RATING = 0;
export const MIN_AVAILABLE_RATING = 1;
export const MAX_RATING = 5;

export const options = {
  responsive: true,
  maintainAspectRatio: true,
  interaction: {
    intersect: true,
    mode: "index",
  },
  elements: { line: { tension: 0 } },
  scales: {
    r: {
      ticks: { color: "#fff", display: false },
      min: MIN_RATING,
      max: MAX_RATING,
      pointLabels: {
        padding: 10,
        color: "#fff",
        font: {
          size: 16,
        },
      },
      angleLines: {
        color: "#2E2E2E",
        lineWidth: 1,
      },
      grid: {
        color: "#2E2E2E",
        lineWidth: 1,
      },
      backgroundColor: "transparent",
    },
  },
  color: "#fff",
  backgroundColor: "#000",
};
