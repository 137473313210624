const baseValue = 3750000;

export const data = [
  { name: "Contribution Profit", base: 0, value: baseValue, color: "#0077b6" },
  { name: "COGS", base: baseValue, value: -170000, color: "#FF6B6B" },
  {
    name: "Estimated Fees",
    base: baseValue - 170000,
    value: -340000,
    color: "#FF6B6B",
  },
  {
    name: "Ad Spend",
    base: baseValue - 510000,
    value: 300000,
    color: "#4CAF50",
  },
  {
    name: "Ad Sales",
    base: baseValue - 210000,
    value: 680000,
    color: "#4CAF50",
  },
  {
    name: "Organic Sales",
    base: baseValue + 470000,
    value: 710000,
    color: "#4CAF50",
  },
  {
    name: "Subscription Sales",
    base: baseValue + 1180000,
    value: 35000,
    color: "#4CAF50",
  },
  {
    name: "What if Contribution Profit",
    base: 0,
    value: 490000,
    color: "#0077b6",
  },
];
