import { useState } from "react";

const useDateRange = () => {
  const getTodayDate = () => {
    const today = new Date();
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const formattedDate = today
      .toLocaleDateString("default", options)
      .replace(/\//g, ".");
    return `${formattedDate} — ${formattedDate}`;
  };

  const [selectedDateRange, setSelectedDateRange] =
    useState<string>(getTodayDate());

  const handleDateChange = (startDate: Date, endDate: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const formattedStartDate = startDate
      .toLocaleDateString("default", options)
      .replace(/\//g, ".");
    const formattedEndDate = endDate
      .toLocaleDateString("default", options)
      .replace(/\//g, ".");
    const formattedDateRange = `${formattedStartDate} — ${formattedEndDate}`;
    setSelectedDateRange(formattedDateRange);
  };

  return { selectedDateRange, handleDateChange };
};

export default useDateRange;
