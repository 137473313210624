import ProductInput from "@components/ProductInput";
import styles from "./styles.module.scss";
import CalendarButton from "@components/CalendarButton";
import { mockProductDate } from "./mock";

interface ProductDateItem {
  productLabel: string;
  productValue: string | number;
  calendarTitle: string;
}

function ProductDate() {
  const { productLabel, productValue, calendarTitle }: ProductDateItem =
    mockProductDate;

  return (
    <div className={styles.container}>
      <div className={styles.productInputBox}>
        <ProductInput label={productLabel} value={productValue} />
      </div>
      <div className={styles.calendarContainer}>
        <div className={styles.calendar}>
          <h4 className={styles.title}>{calendarTitle}</h4>
          <div className={styles.calendarButton}>
            <CalendarButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDate;
