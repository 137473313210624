export default () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 22H23.999" stroke="#8AFF6C" stroke-width="2" />
      <path
        d="M1 16L8 9L13 14L23 4M23 4H16M23 4V11"
        stroke="#8AFF6C"
        stroke-width="2"
      />
    </svg>
  );
};
