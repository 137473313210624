import React from "react";
import styles from "./styles.module.scss";

interface DynamicComparisonBlockProps {
  productScore: number;
  competitorScore: number;
  difference: string;
}

const DynamicComparisonBlock: React.FC<DynamicComparisonBlockProps> = ({
  productScore,
  competitorScore,
  difference,
}) => {
  return (
    <div className={styles.comparisonBlock}>
      <div className={styles.scores}>
        <div className={styles.scoreBox}>
          <p>Score</p>
          <span className={styles.ratingValue}>{productScore}</span>
        </div>

        <div className={styles.difference}>
          <p>
            Your product score is{" "}
            <span className={styles.highlight}>{difference} below</span>{" "}
            selected competitor aspect score
          </p>
        </div>

        <div className={styles.scoreBox}>
          <p>Score</p>
          <span className={styles.ratingValue}>{competitorScore}</span>
        </div>
      </div>
    </div>
  );
};

export default DynamicComparisonBlock;
