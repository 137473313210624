import React, { useEffect, useState } from "react";
import CustomCheckbox from "@components/CustomCheckbox";
import {
  availableMetrics,
  Metric,
} from "@pages/Dashboard/mocks/mockMetricsData";
import InfoIcon from "@assets/icons/i.svg";
import ModalBox from "@components/ModalBox";
import styles from "./styles.module.scss";

interface MetricModalProps {
  isOpen: boolean;
  onClose: () => void;
  onApply: (metrics: Metric[]) => void;
}

const MetricModal: React.FC<MetricModalProps> = ({
  isOpen,
  onClose,
  onApply,
}) => {
  const [metrics, setMetrics] = useState<Metric[]>(availableMetrics);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleCheckboxChange = (index: number) => {
    setMetrics((prevMetrics) => {
      const checkedCount = prevMetrics.filter(
        (metric) => metric.checked,
      ).length;
      const isChecked = prevMetrics[index].checked;
      if (checkedCount >= 6 && !isChecked) {
        return prevMetrics;
      }

      return prevMetrics.map((metric, i) =>
        i === index ? { ...metric, checked: !metric.checked } : metric,
      );
    });
  };

  const handleReset = () => {
    setMetrics(availableMetrics);
  };

  const handleApply = () => {
    onApply(metrics.filter((metric) => metric.checked));
  };

  const checkedCount = metrics.filter((metric) => metric.checked).length;

  return (
    <ModalBox isOpen={isOpen} onClose={onClose} title="Choose Metrics">
      <p className={styles.modalDescription}>
        Choose which metrics you want to show in the «Sales Performance»
      </p>
      <div className={styles.modalMetricsOptions}>
        {metrics.map((metric, index) => (
          <label
            key={index}
            className={`${styles.metricLabel} ${
              checkedCount >= 6 && !metric.checked ? styles.disabled : ""
            }`}
          >
            <CustomCheckbox
              checked={metric.checked}
              onChange={() => handleCheckboxChange(index)}
            />
            {metric.name}
            <img src={InfoIcon} alt="Info" className={styles.infoIcon} />
          </label>
        ))}
      </div>
      <div className={styles.modalActions}>
        <button className={styles.modalButton} onClick={handleReset}>
          Reset to default
        </button>
        <button className={styles.modalButtonApply} onClick={handleApply}>
          Apply
        </button>
      </div>
    </ModalBox>
  );
};

export default MetricModal;
