export const data = [
  {
    date: new Date().getTime() - 5 * 30 * 24 * 3600 * 1000,
    yourProduct: 20,
    competitor: 50,
  },
  {
    date: new Date().getTime() - 4 * 30 * 24 * 3600 * 1000,
    yourProduct: 55,
    competitor: 25,
  },
  {
    date: new Date().getTime() - 3 * 30 * 24 * 3600 * 1000,
    yourProduct: 30,
    competitor: 45,
  },
  {
    date: new Date().getTime() - 2 * 30 * 24 * 3600 * 1000,
    yourProduct: 25,
    competitor: 30,
  },
  {
    date: new Date().getTime() - 30 * 24 * 3600 * 1000,
    yourProduct: 40,
    competitor: 30,
  },
  {
    date: new Date().getTime(),
    yourProduct: 55,
    competitor: 65,
  },
];
