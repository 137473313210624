import React from "react";
import styles from "./styles.module.scss";

interface ImageSectionProps {
  imageUrl: string;
}

const ImageSection: React.FC<ImageSectionProps> = ({ imageUrl }) => (
  <div className={styles.imageSection}>
    {imageUrl && (
      <img src={imageUrl} alt="Product" className={styles.productImage} />
    )}
  </div>
);

export default ImageSection;
