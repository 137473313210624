export const problemDataSet1 = [
  {
    title: "Disappointing smell",
    description:
      "Many reviewers expressed dissatisfaction with the lack of responsiveness and unhelpful nature of the customer service team when they encountered issues with the product or delivery.",
  },
  {
    title: "Consistency issues",
    description:
      "Several users reported inconsistencies in the product quality across different batches.",
  },
  {
    title: "Decreased satisfaction",
    description:
      "User satisfaction has declined over time, possibly due to changes in the product formula or quality.",
  },
  {
    title: "Taste complaints",
    description:
      "Some users have reported that the taste of the product is unpleasant.",
  },
  {
    title: "Formula changes",
    description:
      "Recent changes to the product formula have not been well-received by long-term customers.",
  },
];

export const problemDataSet2 = [
  {
    title: "Limited results",
    description:
      "Several users reported that the design of the second shelf of the product is not practical or efficient.",
  },
  {
    title: "False advertising",
    description:
      "Some users have claimed that the product does not match the descriptions and promises made in advertisements.",
  },
  {
    title: "Brittle nails",
    description:
      "Customers have reported experiencing brittle nails after using the product.",
  },
  {
    title: "Expensive",
    description:
      "Many users find the product to be too expensive for the value it provides.",
  },
  {
    title: "Slow growth",
    description:
      "The product does not promote hair growth as quickly as advertised.",
  },
];
