import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import ProgressPhoto from "@assets/icons/progressPhoto";
import GreenArrow from "@assets/icons/greenArrow";
import { PhotoCardData } from "../../mock";
import { ROUTES } from "src/router/routes";

const PhotoCard: React.FC<PhotoCardData> = ({
  imageUrl,
  optimizationScore,
  ctrChange,
  salesChange,
  ctrValue,
  salesValue,
  id,
  imageDescription,
  imageCritique,
  hypothesis,
  percentageImpact,
  howToTest,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${ROUTES.CRO}/${ROUTES.CRO_PHOTOOPTIMISATION}/${id}`, {
      state: {
        imageUrl,
        optimizationScore,
        ctrChange,
        salesChange,
        ctrValue,
        salesValue,
        imageDescription,
        imageCritique,
        hypothesis,
        percentageImpact,
        howToTest,
      },
    });
  };

  return (
    <div className={styles.photoCard} onClick={handleClick}>
      <img src={imageUrl} alt="Product" className={styles.productImage} />
      <div className={styles.description}>
        <div className={styles.progressIcon}>
          <ProgressPhoto />
        </div>
        <div className={styles.textBlock}>
          <div className={styles.optimizationText}>
            Photo requires optimization
          </div>
          <div className={styles.optimizationDetails}>
            Replacing this photo is estimated to add{" "}
            <span>{optimizationScore}</span> to Full Product Optimization Score
          </div>
        </div>
      </div>
      <div className={styles.metrics}>
        <div className={styles.ctr}>
          CTR
          <span className={styles.ctrChange}>
            {ctrChange} <GreenArrow />
          </span>
        </div>
        <div className={styles.sales}>
          SALES
          <span className={styles.salesChange}>
            {salesChange} <GreenArrow />
          </span>
        </div>
      </div>
    </div>
  );
};

export default PhotoCard;
