import React, { useState } from "react";
import styles from "./styles.module.scss";
import CustomCheckbox from "@components/CustomCheckbox";

const VideoParams: React.FC = () => {
  const [checkboxes, setCheckboxes] = useState([
    {
      label: "Show Hypothesis",
      checked: true,
      setChecked: (value: boolean) =>
        setCheckboxes((prev) =>
          prev.map((cb, index) =>
            index === 0 ? { ...cb, checked: value } : cb,
          ),
        ),
    },
    {
      label: "Show Voice",
      checked: true,
      setChecked: (value: boolean) =>
        setCheckboxes((prev) =>
          prev.map((cb, index) =>
            index === 1 ? { ...cb, checked: value } : cb,
          ),
        ),
    },
    {
      label: "Show Frames Video Upload",
      checked: true,
      setChecked: (value: boolean) =>
        setCheckboxes((prev) =>
          prev.map((cb, index) =>
            index === 2 ? { ...cb, checked: value } : cb,
          ),
        ),
    },
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.checkBoxContainer}>
        {checkboxes.map((checkbox, index) => (
          <label key={index} className={styles.label}>
            <CustomCheckbox
              blueBorder={true}
              checked={checkbox.checked}
              onChange={() => checkbox.setChecked(!checkbox.checked)}
            />
            <span>{checkbox.label}</span>
          </label>
        ))}
      </div>
      <div>
        <button className={styles.button}>UPLOAD FILE</button>
      </div>
    </div>
  );
};

export default VideoParams;
