import { useState } from "react";
import styles from "./styles.module.scss";
import ToolsIcon from "@assets/icons/toolsIcon";
import ToolsPopup from "./ToolsPopup";

function Tools() {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const handleClose = () => {
    setIsActive(false);
  };

  return (
    <div className={styles.container}>
      <div className={isActive ? styles.visibleBox : styles.hiddenBox}>
        <div
          className={`${styles.tools} ${isActive ? styles.active : ""}`}
          onClick={handleClick}
        >
          <ToolsIcon />
        </div>
        {isActive && <ToolsPopup onClose={handleClose} />}
      </div>
      <button className={styles.button}>save changes</button>
    </div>
  );
}

export default Tools;
