import React from "react";
import styles from "./styles.module.scss";

interface ButtonsSectionProps {
  type: "video" | "image";
  toggleHypothesisVisibility: () => void;
  isHypothesisVisible: boolean;
}

const ButtonsSection: React.FC<ButtonsSectionProps> = ({
  toggleHypothesisVisibility,
  isHypothesisVisible,
  type,
}) => (
  <div className={styles.buttonsContainer}>
    <button className={styles.cancelButton}>Cancel</button>
    <div className={styles.btnContainer}>
      {isHypothesisVisible && (
        <button className={styles.updatePhotoButton}>
          Update {type === "video" ? "Video" : "Photo"}
        </button>
      )}
      <button
        className={styles.applyChangesButton}
        onClick={toggleHypothesisVisibility}
      >
        {isHypothesisVisible ? "Apply Changes" : "Get a Hypothesis"}
      </button>
    </div>
  </div>
);

export default ButtonsSection;
