import { Product } from "@services/amazon/products/types";
import { create } from "zustand";

interface ProductsState {
  productOwn: Product[];
  productPublic: Product[];
  setProductOwn: (products: Product[]) => void;
  setProductPublic: (products: Product[]) => void;
}

export const useProductsStore = create<ProductsState>((set) => ({
  productOwn: [],
  productPublic: [],

  setProductOwn: (products) => set({ productOwn: products }),
  setProductPublic: (products) => set({ productPublic: products }),
}));
