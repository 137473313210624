import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./styles.module.scss";
import ImageSection from "./сomponents/ImageSection";
import FormSection from "./сomponents/FormSection";
import ButtonsSection from "../../../components/OptimisationInfoSection/ButtonSection";
import { VisibleDescriptions } from "../../../components/OptimisationInfoSection/types";
import OptimisationPageWrapper from "../../../components/OptimisationPageWrapper";
import InfoSection from "../../../components/OptimisationInfoSection";

const PhotoOptimisationDetails: React.FC = () => {
  const [isHypothesisVisible, setIsHypothesisVisible] = useState(false);
  const [visibleDescriptions, setVisibleDescriptions] =
    useState<VisibleDescriptions>({
      description: false,
      critique: false,
      hypothesis: false,
      howToTest: false,
      percentageImpact: false,
    });

  const location = useLocation();

  const {
    imageUrl,
    imageDescription,
    imageCritique,
    hypothesis,
    howToTest,
    percentageImpact,
  } = location.state || {};

  const toggleHypothesisVisibility = () => {
    setIsHypothesisVisible((prevState) => !prevState);
  };

  const toggleVisibility = (field: keyof VisibleDescriptions) => {
    setVisibleDescriptions((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <OptimisationPageWrapper>
      <div className={styles.detailsContainer}>
        <ImageSection imageUrl={imageUrl} />
        <FormSection />
      </div>
      {isHypothesisVisible && (
        <InfoSection
          type="image"
          description={imageDescription}
          critique={imageCritique}
          hypothesis={hypothesis}
          howToTest={howToTest}
          percentageImpact={percentageImpact}
          visibleDescriptions={visibleDescriptions}
          toggleVisibility={toggleVisibility}
        />
      )}
      <ButtonsSection
        type="image"
        toggleHypothesisVisibility={toggleHypothesisVisibility}
        isHypothesisVisible={isHypothesisVisible}
      />
    </OptimisationPageWrapper>
  );
};

export default PhotoOptimisationDetails;
