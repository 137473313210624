import PageWrapper from "@components/PageWrapper/PageWrapper";
import Products from "@pages/Compare/components/Products/index";
import Header from "@pages/Compare/components/Header/index";
import ReviewScore from "@pages/Compare/components/ReviewScore/index";
import ProblemComponent from "./components/Problems";
import TopPhrasesComponent from "./components/TopPhrases";
import Hypotheses from "./components/Hypotheses";
import ProductComparison from "./components/ProductComparison";

function Compare() {
  return (
    <PageWrapper>
      <Header />
      <Products />
      <ProductComparison />
      <ReviewScore />
      <ProblemComponent />
      <TopPhrasesComponent />
      <Hypotheses />
    </PageWrapper>
  );
}

export default Compare;
