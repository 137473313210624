import { Chart } from "chart.js";
import { useEffect } from "react";
import { tooltipArrayState } from "./tooltip-types";
import { MAX_RATING, MIN_AVAILABLE_RATING } from "./chart-data";

export const useMoveDot = (
  chartRef: React.RefObject<Chart<"radar", number[], string>>,
) => {
  useEffect(() => {
    if (!chartRef.current) return;
    const chart = chartRef.current;
    const canvas = chart.canvas;
    const productData = chart.data.datasets[0]?.data;
    if (!productData) return;

    const lengthInPixelToLine = 60;
    const ratio = lengthInPixelToLine / MAX_RATING;

    canvas.addEventListener("dragstart", () => false);
    canvas.addEventListener("mousedown", (e) => {
      if (chart.tooltip?.opacity === 0) return;
      const index = chart.tooltip?.dataPoints[0]?.dataIndex ?? -1;
      const activeState = tooltipArrayState[index];
      if (!activeState) return;

      const startX = e.clientX;
      const startY = e.clientY;
      const startData = productData[index];

      const mousemove = (e: MouseEvent) => {
        const offsetX = (startX - e.clientX) / ratio;
        const offsetY = (startY - e.clientY) / ratio;
        const offset =
          (activeState.dragInfo.direction === "x" ? offsetX : offsetY) *
          (activeState.dragInfo.reverse ? -1 : 1);
        const newData = startData + offset;

        productData[index] = Number(
          (newData < MIN_AVAILABLE_RATING
            ? MIN_AVAILABLE_RATING
            : newData > 5
              ? 5
              : newData
          ).toFixed(2),
        );
        chart.update();
      };
      canvas.addEventListener("mousemove", mousemove);
      canvas.addEventListener(
        "mouseup",
        () => {
          canvas.removeEventListener("mousemove", mousemove);
        },
        { once: true },
      );
    });
  }, [chartRef]);
};
