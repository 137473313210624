import React from "react";
import styles from "./styles.module.scss";
import { formFields } from "../../mock";

const FormSection: React.FC = () => (
  <div className={styles.formSection}>
    <form>
      {formFields.map((field) => (
        <div className={styles.formGroup} key={field.id}>
          <label htmlFor={field.id}>{field.label}</label>
          <input type="text" id={field.id} name={field.name} />
        </div>
      ))}
    </form>
  </div>
);

export default FormSection;
