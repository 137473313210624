import { Button } from "antd";
import styles from "./styles.module.scss";
import { useState, useEffect, DragEvent, ChangeEvent } from "react";
import CloseModal from "src/assets/icons/closeModal.svg";
import SearchIcon from "src/assets/icons/SearchIcon.svg";
import greySquare from "src/assets/icons/greySquare.svg";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import MetricsList from "./metricsList";
import CloseIcon from "@components/CloseIcon";

interface ConfigModalProps {
  buttonWidth: number;
  closeModal: () => void;
  initialWidgets: IWidget[];
  setWidgets: (widgets: IWidget[]) => void;
  onApply: (widgets: IWidget[]) => void;
}

interface IWidgetExtended extends IWidget {
  selected?: boolean;
}

export const ConfigModal = ({
  buttonWidth,
  closeModal,
  initialWidgets,
  setWidgets,
  onApply,
}: ConfigModalProps) => {
  const [items, setItems] = useState<IWidgetExtended[]>(initialWidgets);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [widgetList, setWidgetList] =
    useState<IWidgetExtended[]>(initialWidgets);
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);

  useEffect(() => {
    setWidgetList(initialWidgets);
  }, [initialWidgets]);

  const handleDragStart = (e: DragEvent<HTMLDivElement>, index: number) => {
    setDraggingIndex(index);
    e.dataTransfer.setData("widgetIndex", index.toString());
  };

  const handleDrop = () => {
    setDraggingIndex(null);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>, hoverIndex: number) => {
    e.preventDefault();
    if (draggingIndex === null || draggingIndex === hoverIndex) return;
    const tempList = [...widgetList];
    const draggedWidget = tempList[draggingIndex];
    tempList.splice(draggingIndex, 1);
    tempList.splice(hoverIndex, 0, draggedWidget);
    setWidgetList(tempList);
    setDraggingIndex(hoverIndex);
  };

  const handleDragEnd = () => {
    setDraggingIndex(null);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleToggleMetric = (metric: IWidgetExtended) => {
    setItems((prevItems) => {
      const exists = prevItems.some((item) => item.id === metric.id);
      if (exists) {
        return prevItems.filter((item) => item.id !== metric.id);
      } else if (prevItems.length < 8) {
        return [...prevItems, metric];
      }
      return prevItems;
    });

    setWidgetList((prevList) => {
      const exists = prevList.some((item) => item.id === metric.id);
      if (exists) {
        return prevList.filter((item) => item.id !== metric.id);
      } else if (prevList.length < 8) {
        return [...prevList, metric];
      }
      return prevList;
    });
  };

  const handleApply = () => {
    setWidgets(widgetList);
    onApply(widgetList);
    closeModal();
  };

  return (
    <div className={styles.container}>
      <div className={styles.rumpBox}>
        <div
          className={styles.rump}
          style={{
            width: `calc(30% - ${Math.round(buttonWidth)}px)`,
            marginLeft: "auto",
            marginRight: "-20px",
          }}
        />
        <div
          className={styles.divider}
          style={{ width: `${Math.round(buttonWidth + 20)}px` }}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.title}>Current Metrics</p>
          <p className={styles.close}>
            <button onClick={closeModal}>
              <img
                width={8}
                height={8}
                src={CloseModal}
                alt="Close"
                className={styles.cancelIcon}
              />
              cancel
            </button>
          </p>
        </div>
        <div className={styles.gridBox}>
          {widgetList.map((widget, index) => (
            <div
              key={widget.id}
              className={styles.dashboardItem}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDrop={handleDrop}
              onDragOver={(e) => handleDragOver(e, index)}
              onDragEnd={handleDragEnd}
            >
              <div className={styles.itemContent}>
                <div className={styles.itemContentHeader}>
                  <div className={styles.itemDragHandle}>
                    <img alt="Square" src={greySquare} width={14} height={14} />
                  </div>
                  <CloseIcon onClick={() => handleToggleMetric(widget)} />
                </div>
                <div className={styles.itemContentName}>{widget.name}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.metrics}>
          <div className={styles.metricsHeader}>
            <p className={styles.title}>Add Metrics</p>
            <div className={styles.searchContainer}>
              <input
                type="text"
                className={styles.searchInput}
                placeholder="Search by name"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <img
                src={SearchIcon}
                alt="Search"
                className={styles.searchIcon}
              />
            </div>
          </div>
          <MetricsList
            searchQuery={searchQuery}
            onToggleMetric={handleToggleMetric}
            currentMetrics={items}
          />
        </div>
      </div>
      <Button
        type="primary"
        shape="round"
        className={styles.applyBtn}
        onClick={handleApply}
      >
        Apply
      </Button>
    </div>
  );
};
