import React, { useRef } from "react";
import styles from "./styles.module.scss";
import { videoCardMock } from "../../mock";

const VideoSection: React.FC = () => {
  const { videoUrl, videoDescription } = videoCardMock;
  const textRef = useRef<HTMLParagraphElement>(null);

  return (
    <div className={styles.container}>
      <video className={styles.video} controls>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <p className={styles.text} ref={textRef}>
        {videoDescription}
      </p>
    </div>
  );
};

export default VideoSection;
