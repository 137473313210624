import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSectionStore } from "src/store/croModule.state";
import styles from "./styles.module.scss";
import ProductDetailPage from "../../../Product";

function Sections() {
  const sectionsBefore = useSectionStore((state) => state.sectionsBefore);
  const sectionsAfter = useSectionStore((state) => state.sectionsAfter);
  const reorderSections = useSectionStore((state) => state.reorderSections);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    reorderSections(result.source, result.destination);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="sectionsBefore">
        {(provided) => (
          <div
            className={styles.section}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {sectionsBefore.map((section, index) => (
              <Draggable
                key={section.id}
                draggableId={section.id.toString()}
                index={index}
              >
                {(provided) => (
                  <div
                    className={styles.container}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {React.cloneElement(
                      section.component as React.ReactElement<any>,
                      {
                        id: section.id,
                      },
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <div className={styles.productDetailContainer}>
        <ProductDetailPage />
      </div>
      <Droppable droppableId="sectionsAfter">
        {(provided) => (
          <div
            className={styles.section}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {sectionsAfter.map((section, index) => (
              <Draggable
                key={section.id}
                draggableId={section.id.toString()}
                index={index}
              >
                {(provided) => (
                  <div
                    className={styles.container}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {React.cloneElement(
                      section.component as React.ReactElement<any>,
                      {
                        id: section.id,
                      },
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default Sections;
