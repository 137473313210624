import { ROUTES } from "src/router/routes";

export const items = [
  {
    title: "Listing optimization",
    initialValue: 40,
    route: ROUTES.CRO_OPTIMISATIONDETAINS,
  },
  {
    title: "Photo optimization",
    initialValue: 50,
    route: ROUTES.CRO_PHOTOOPTIMISATION,
  },
  {
    title: "Video optimization",
    initialValue: 65,
    route: ROUTES.CRO_VIDEOOPTIMISATION,
  },
  {
    title: "A+ content optimization",
    initialValue: 50,
    route: "/a-plus-content-optimization",
  },
];
