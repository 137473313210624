export default () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 22H23.999" stroke="#FE5858" stroke-width="2" />
      <path
        d="M1 4L8 11L13 6L23 16M23 16H16M23 16V9"
        stroke="#FE5858"
        stroke-width="2"
      />
    </svg>
  );
};
