import React, { useState } from "react";
import styles from "./styles.module.scss";
import Picture from "@assets/img/productImg1.png";
import BottomArrow from "@assets/icons/bottomArrow";
import LoadMoreButton from "@components/LoadMoreButton";
import { Products } from "@pages/Dashboard/mocks/products";
import Settings from "@assets/img/settings";
import ProductCode from "@components/ProductCode";
import ProblemsBlock from "@components/ProblemsBlock";
import { useModalStore } from "src/store/modal.state";

const Table: React.FC = () => {
  const [visibleProducts, setVisibleProducts] = useState(3);
  const [expandedProductId, setExpandedProductId] = useState<number | null>(
    null,
  );

  const openModal = useModalStore((state) => state.openModal);

  const handleLoadMore = () => {
    setVisibleProducts((prev) => prev + 3);
  };

  const toggleExpand = (productId: number) => {
    setExpandedProductId((prev) => (prev === productId ? null : productId));
  };

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.headerCell}>PRODUCT</th>
            <th className={styles.headerCell}>SESSION TOTAL</th>
            <th className={styles.headerCell}>PAGE VIEWS TOTAL</th>
            <th className={styles.headerCell}>
              featured offer <br /> (buy box) <br />
              percentage
            </th>
            <th className={styles.headerCell}>UNITS ORDERED</th>
            <th className={styles.headerCell}>
              UNITS SESSION <br /> PERCENTAGE
            </th>
            <th className={styles.headerCell}>
              ORDERED PRODUCT <br />
              SALES
            </th>
            <th className={styles.headerCell}>TOTAL ORDER ITEMS</th>
            <th className={styles.headerCell}>
              <button onClick={openModal}>
                <Settings />
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {Products.slice(0, visibleProducts).map((product, index) => (
            <React.Fragment key={index}>
              <tr
                className={`${styles.row} ${expandedProductId === index ? styles.expanded : ""}`}
              >
                <td className={styles.cell}>
                  <div className={styles.productInfo}>
                    <img
                      src={Picture}
                      alt="Photo"
                      className={styles.productImage}
                    />
                    <div className={styles.productDetails}>
                      <p>{product.title}</p>
                      <ProductCode code={"B07GWVP5RC"} />
                    </div>
                  </div>
                </td>
                <td className={styles.cell}>
                  <div>{product.sessionTotal}</div>
                </td>
                <td className={styles.cell}>
                  <div>{product.pageViewsTotal}</div>
                </td>
                <td className={styles.cell}>
                  <div>{product.featuredOffer}%</div>
                </td>
                <td className={styles.cell}>
                  <div>{product.unitsOrdered}</div>
                </td>
                <td className={styles.cell}>
                  <div>{product.unitsSessionPercentage}%</div>
                </td>
                <td className={styles.cell}>
                  <div>${product.orderedProductSales}</div>
                </td>
                <td className={styles.cell}>
                  <div>{product.totalOrderItems}</div>
                </td>
                <td className={styles.cell}>
                  <button
                    className={`${styles.expandButton} ${expandedProductId === index ? styles.rotated : ""}`}
                    onClick={() => toggleExpand(index)}
                  >
                    <BottomArrow />
                  </button>
                </td>
              </tr>
              {expandedProductId === index && (
                <tr className={styles.expandedRow}>
                  <td colSpan={8} className={styles.expandedCell}>
                    <ProblemsBlock topProblems={product.topProblems} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {visibleProducts < Products.length && (
        <LoadMoreButton onClick={handleLoadMore} />
      )}
    </div>
  );
};

export default Table;
