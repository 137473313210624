import { Year } from "./types";

export const mockData: Year[] = [
  {
    year: "2023",
    reviewScore: 3.06,
    negativePriceAspect: "14%",
    price: "$29.78",
    priceSubscribers: "$27.67",
    discountSubscribers: "7%",
    buyBox: "85%",
    n: 0.24,
    cogs: 4.69,
    estimatedFees: 9.04,
    estimatedStorageFees: 0.02,
    cac: 18.9,
    ltv: 103.4,
    ltvCac: 5.47,
    roi: 447,
    quarters: [
      {
        quarter: "Q1",
        reviewScore: 3.16,
        negativePriceAspect: "13%",
        price: "$30.00",
        priceSubscribers: "$28.00",
        discountSubscribers: "8%",
        buyBox: "86%",
        n: 0.25,
        cogs: 4.7,
        estimatedFees: 9.1,
        estimatedStorageFees: 0.03,
        cac: 19.0,
        ltv: 104.0,
        ltvCac: 5.5,
        roi: 450,
        months: [
          {
            month: "January",
            reviewScore: 3.26,
            negativePriceAspect: "12%",
            price: "$31.00",
            priceSubscribers: "$29.00",
            discountSubscribers: "9%",
            buyBox: "87%",
            n: 0.26,
            cogs: 4.71,
            estimatedFees: 9.2,
            estimatedStorageFees: 0.04,
            cac: 19.1,
            ltv: 105.0,
            ltvCac: 5.6,
            roi: 460,
            weeks: [
              {
                week: "Week 1",
                reviewScore: 3.36,
                negativePriceAspect: "11%",
                price: "$32.00",
                priceSubscribers: "$30.00",
                discountSubscribers: "10%",
                buyBox: "88%",
                n: 0.27,
                cogs: 4.72,
                estimatedFees: 9.3,
                estimatedStorageFees: 0.05,
                cac: 19.2,
                ltv: 106.0,
                ltvCac: 5.7,
                roi: 470,
                days: [
                  {
                    day: "Monday",
                    reviewScore: 3.46,
                    negativePriceAspect: "10%",
                    price: "$33.00",
                    priceSubscribers: "$31.00",
                    discountSubscribers: "11%",
                    buyBox: "89%",
                    n: 0.28,
                    cogs: 4.73,
                    estimatedFees: 9.4,
                    estimatedStorageFees: 0.06,
                    cac: 19.3,
                    ltv: 107.0,
                    ltvCac: 5.8,
                    roi: 480,
                  },
                  {
                    day: "Tuesday",
                    reviewScore: 3.36,
                    negativePriceAspect: "11%",
                    price: "$32.50",
                    priceSubscribers: "$30.50",
                    discountSubscribers: "10.5%",
                    buyBox: "88.5%",
                    n: 0.27,
                    cogs: 4.72,
                    estimatedFees: 9.35,
                    estimatedStorageFees: 0.055,
                    cac: 19.25,
                    ltv: 106.5,
                    ltvCac: 5.75,
                    roi: 475,
                  },
                ],
              },
              {
                week: "Week 2",
                reviewScore: 3.36,
                negativePriceAspect: "11%",
                price: "$32.00",
                priceSubscribers: "$30.00",
                discountSubscribers: "10%",
                buyBox: "88%",
                n: 0.27,
                cogs: 4.72,
                estimatedFees: 9.3,
                estimatedStorageFees: 0.05,
                cac: 19.2,
                ltv: 106.0,
                ltvCac: 5.7,
                roi: 470,
                days: [
                  {
                    day: "Wednesday",
                    reviewScore: 3.46,
                    negativePriceAspect: "10%",
                    price: "$33.00",
                    priceSubscribers: "$31.00",
                    discountSubscribers: "11%",
                    buyBox: "89%",
                    n: 0.28,
                    cogs: 4.73,
                    estimatedFees: 9.4,
                    estimatedStorageFees: 0.06,
                    cac: 19.3,
                    ltv: 107.0,
                    ltvCac: 5.8,
                    roi: 480,
                  },
                ],
              },
            ],
          },
          {
            month: "February",
            reviewScore: 3.26,
            negativePriceAspect: "12%",
            price: "$31.00",
            priceSubscribers: "$29.00",
            discountSubscribers: "9%",
            buyBox: "87%",
            n: 0.26,
            cogs: 4.71,
            estimatedFees: 9.2,
            estimatedStorageFees: 0.04,
            cac: 19.1,
            ltv: 105.0,
            ltvCac: 5.6,
            roi: 460,
            weeks: [],
          },
          {
            month: "March",
            reviewScore: 3.26,
            negativePriceAspect: "12%",
            price: "$31.00",
            priceSubscribers: "$29.00",
            discountSubscribers: "9%",
            buyBox: "87%",
            n: 0.26,
            cogs: 4.71,
            estimatedFees: 9.2,
            estimatedStorageFees: 0.04,
            cac: 19.1,
            ltv: 105.0,
            ltvCac: 5.6,
            roi: 460,
            weeks: [],
          },
        ],
      },
      {
        quarter: "Q2",
        reviewScore: 3.26,
        negativePriceAspect: "12%",
        price: "$31.00",
        priceSubscribers: "$29.00",
        discountSubscribers: "9%",
        buyBox: "87%",
        n: 0.26,
        cogs: 4.71,
        estimatedFees: 9.2,
        estimatedStorageFees: 0.04,
        cac: 19.1,
        ltv: 105.0,
        ltvCac: 5.6,
        roi: 460,
        months: [
          {
            month: "April",
            reviewScore: 3.3,
            negativePriceAspect: "13%",
            price: "$32.00",
            priceSubscribers: "$30.00",
            discountSubscribers: "10%",
            buyBox: "88%",
            n: 0.27,
            cogs: 4.72,
            estimatedFees: 9.3,
            estimatedStorageFees: 0.05,
            cac: 19.2,
            ltv: 106.0,
            ltvCac: 5.7,
            roi: 470,
            weeks: [],
          },
          {
            month: "May",
            reviewScore: 3.28,
            negativePriceAspect: "11%",
            price: "$31.50",
            priceSubscribers: "$29.50",
            discountSubscribers: "9.5%",
            buyBox: "87.5%",
            n: 0.265,
            cogs: 4.715,
            estimatedFees: 9.25,
            estimatedStorageFees: 0.045,
            cac: 19.15,
            ltv: 105.5,
            ltvCac: 5.65,
            roi: 465,
            weeks: [],
          },
        ],
      },
    ],
  },
  {
    year: "2022",
    reviewScore: 3.56,
    negativePriceAspect: "15%",
    price: "$28.50",
    priceSubscribers: "$26.50",
    discountSubscribers: "8%",
    buyBox: "84%",
    n: 0.22,
    cogs: 4.55,
    estimatedFees: 8.75,
    estimatedStorageFees: 0.018,
    cac: 17.5,
    ltv: 100.0,
    ltvCac: 5.25,
    roi: 440,
    quarters: [],
  },
];
