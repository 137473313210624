import React, { useState } from "react";
import styles from "./styles.module.scss";
import croProduct from "@assets/img/croProduct.png";
import SliderImg1 from "@assets/img/sliderImg1.png";
import SliderImg2 from "@assets/img/sliderImg2.png";
import SliderImg3 from "@assets/img/sliderImg3.png";
import SliderImg4 from "@assets/img/sliderImg4.png";
import SliderImg6 from "@assets/img/sliderImg6.png";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const images = [
  croProduct,
  SliderImg1,
  SliderImg2,
  SliderImg3,
  SliderImg4,
  SliderImg6,
];

const ProductImage: React.FC = () => {
  const [activeImage, setActiveImage] = useState(images[0]);

  const handleThumbnailClick = (image: string) => {
    setActiveImage(image);
  };

  return (
    <div className={styles.productImage}>
      <div className={styles.imageContainer}>
        <TransformWrapper>
          <TransformComponent>
            <img src={activeImage} alt="Product" className={styles.zoomImage} />
          </TransformComponent>
        </TransformWrapper>
      </div>
      <div className={styles.thumbnailSlider}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index + 1}`}
            onClick={() => handleThumbnailClick(image)}
            className={activeImage === image ? styles.activeThumbnail : ""}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductImage;
