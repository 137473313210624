import React from "react";
import styles from "./styles.module.scss";
import ProductDetails from "./ProductDetails";
import ProductImage from "./ProductImage";
import ProductCard from "./ProductCard";
import ProductHeaderTitle from "./components/ProductHeaderTitle";
import VideoSection from "./VideoSection";
import Optimisation from "./Optimisation";

const ProductDetailPage: React.FC = () => {
  return (
    <div className={styles.productDetaiBox}>
      <ProductCard />
      <div>
        <ProductHeaderTitle title="Product detail page" />
        <div className={styles.productDetailPage}>
          <div className={styles.imageAndDetails}>
            <ProductImage />
            <ProductDetails />
          </div>
          <VideoSection />
          <div className={styles.aPlusContent}>
            <h2>A+ Content</h2>
          </div>
        </div>
      </div>
      <Optimisation />
    </div>
  );
};

export default ProductDetailPage;
