export default () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.1149 0.00684409C31.0752 0.0914631 31.785 0.938508 31.7004 1.89877C31.494 4.24102 30.986 6.2629 30.0116 8.10351C29.0372 9.94412 27.6506 11.5008 25.8295 12.9883C23.41 14.9644 20.7858 14.9335 18.7566 14.9096L18.6986 14.9089C16.5654 14.884 15.0321 14.887 13.5217 15.9658C11.2435 17.5931 10.6805 19.7675 10.7613 23.2321C10.7837 24.1958 10.0207 24.9953 9.05697 25.0177C8.09325 25.0402 7.29379 24.2771 7.27133 23.3134C7.18103 19.4385 7.79685 15.7651 11.4927 13.1252C13.968 11.3571 16.5499 11.3902 18.5326 11.4157C18.6023 11.4166 18.6712 11.4175 18.7394 11.4183C20.8435 11.4429 22.265 11.3923 23.6212 10.2846C25.1851 9.00727 26.2248 7.79538 26.9264 6.47016C27.628 5.14494 28.0457 3.60376 28.223 1.59234C28.3076 0.632075 29.1546 -0.0777749 30.1149 0.00684409ZM2.03451 0.000106386C2.9985 0.000106386 3.77996 0.78157 3.77996 1.74556V27.9273C3.77996 28.2486 4.04045 28.5091 4.36178 28.5091H29.9617C30.9257 28.5091 31.7071 29.2906 31.7071 30.2546C31.7071 31.2185 30.9257 32 29.9617 32H4.36178C2.11248 32 0.289062 30.1766 0.289062 27.9273V1.74556C0.289062 0.78157 1.07053 0.000106386 2.03451 0.000106386Z"
        fill="#5C5C5A"
      />
    </svg>
  );
};
