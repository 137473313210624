import styles from "./styles.module.scss";
import SectionContainer from "../components/SectionContainer";
import TopSectionIcons from "../components/TopSectionIcons";
import { metricsData, MetricData } from "./mock";
import GaugeSVG from "./GaugeSVG";

type MetricBuilderProps = {
  handleRemove: () => void;
};

const MetricBuilder = ({ handleRemove }: MetricBuilderProps) => {
  return (
    <SectionContainer>
      <TopSectionIcons handleRemove={handleRemove} isContentClosed={false} />
      <div className={styles.filters}>
        <h3 className={styles.title}>Metric Builder</h3>
        <div className={styles.selects}>
          <div className={styles.selectBox}>
            <select className={styles.select}>
              <option>Metrics</option>
            </select>
          </div>
          <div className={styles.selectBox}>
            <select className={styles.select}>
              <option>Comparing to previous period</option>
            </select>
          </div>
        </div>
      </div>
      <div className={styles.metricsGrid}>
        {metricsData.map((metric: MetricData, index: number) => (
          <div key={index} className={styles.metricCard}>
            <div className={styles.metricHeader}>
              <span>{metric.name}</span>
            </div>
            <div className={styles.metricBody}>
              <GaugeSVG percentage={10} />
              <div className={styles.valuesBox}>
                <div className={styles.metricValue}>
                  ${metric.value.toLocaleString()}
                </div>
                <div
                  className={styles.metricChange}
                  style={{ color: metric.color }}
                >
                  {metric.change > 0
                    ? `+${metric.change}%`
                    : `${metric.change}%`}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};

export default MetricBuilder;
