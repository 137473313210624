import React from "react";
import { NavLink } from "react-router-dom";
import { ProductPopup } from "./ProductPopup";

export type NavItem = {
  text: string;
  url?: string;
  onClick?: () => void;
};

export const createNavItems = (
  navItems: NavItem[],
  menuOpen: boolean,
  setMenuOpen: (open: boolean) => void,
  popupRef: React.RefObject<HTMLDivElement>,
): JSX.Element[] => {
  return navItems.map((item) => (
    <React.Fragment key={item.text}>
      {item.url ? (
        <NavLink
          to={item.url}
          className={({ isActive }) =>
            `app-header__nav-item text-button ${isActive ? "active" : ""}`
          }
        >
          {item.text}
        </NavLink>
      ) : (
        <div style={{ position: "relative", zIndex: 100 }} ref={popupRef}>
          <button
            className={`app-header__nav-button text-button ${menuOpen ? "activeProduct" : ""}`}
            onClick={item.onClick}
          >
            {item.text}
          </button>
          {menuOpen && <ProductPopup closePopup={() => setMenuOpen(false)} />}
        </div>
      )}
    </React.Fragment>
  ));
};
